import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainService, PaginationService } from 'src/app/services';
import { MatDialog } from '@angular/material';
import { AlertDialog } from 'src/app/lib';

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class TeachersComponent implements OnInit {
  
  search: any = '';
  Items: any = [];

  currentPage: any = 1;
  sortBy: any = 'DESC';
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  count: any;
  searchTimer: any;
  sub: Subscription;
  id:any;
  constructor(private router: Router, private mainService: MainService,protected dialog: MatDialog, protected _route: ActivatedRoute, protected paginationService: PaginationService) { }

  ngOnInit(): void {
    localStorage.removeItem('teacher');
    this.getList(1);
  }
  addTeacher(){
    this.router.navigateByUrl('/main/teachers/add');
  }
  editTeacher(item){
    localStorage.setItem('teacher', JSON.stringify(item))
    this.router.navigateByUrl('/main/teachers/update/'+item._id);
  }
  viewTeacher(item){
    // this.router.navigateByUrl('/main/view-teacher');

    this.router.navigateByUrl('/main/teachers/detail/'+ item._id);
  }
  viewTe(item){
    this.router.navigateByUrl('/main/teachers/payment/'+ item._id);
  }
  setPage(pageDate: any) {
    this.currentPage = pageDate.page;
    this.perPage = pageDate.perPage;
    this.index = this.currentPage;
    this.getList(pageDate.page);
  }
  onSearch()
  {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
          this.getList(1);
      }, 800);
  }
  toggleView(item){
    console.log(item.otherProps.profile.enabled);
    var data = {
      enabled : item.otherProps.profile.enabled
    }


    var url = 'update/teacher/' + item._id + '/profile-status';
        this.mainService.patch(url, data).then(response => {
                if (response.code == 200 || response.code == 201) {
                  
                }
                else {
                    item.otherProps.profile.enabled = !item.otherProps.profile.enabled
                }
            },
            Error => {
                let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
                let cm = dialogRef.componentInstance;
                cm.heading = 'Error';
                cm.message = 'Internal Server Error';
                cm.cancelButtonText = 'Close';
                cm.type = 'error';
            });

}
  getList(index: any) {

    var url = 'view/teachers?page=' + index + '&perPage=' + this.perPage;

    if (this.search != '') {
      url = url + '&search=' + this.search;
    }
    this.mainService.getList(url).then(response => {
      if (response.code == 200 || response.code === 201) {
        console.log(response.data);
        this.Items = response.data.listing;


        this.count = response.data.pagination.count;
        this.currentPage = response.data.pagination.page;
        this.pages = this.paginationService.setPagination(response.data.pagination.count, this.currentPage, this.perPage);
        this.totalPages = this.pages.totalPages;
      }
      else {
        this.Items = [];

      }
    },
      Error => {

      })
  }
}
