<div class="category">
    <div class="card">
        <div class="back-arrow" style="margin: 25px 25px 0px 40px">
            <div class="row">
                <a [routerLink]="[ '/main/teachers' ]"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 *ngIf="!isUpdate" style="margin-bottom: 0px; color: #000000;">ADD TEACHER</h5>
                <h5 *ngIf="isUpdate" style="margin-bottom: 0px; color: #000000;">UPDATE TEACHER</h5>
            </div>
        </div>
        <p style="margin-left: 80px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Here you can add or edit teacher details</p>

        <div class="noti-details">
            <form [formGroup]="Form"
            (ngSubmit)="doSubmitForm()">
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input formControlName="firstName" placeholder="Fred" matInput>
                        </mat-form-field>


                        <div *ngIf="!isUpdate" class="input-group">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Email ID</mat-label>
                                <input formControlName="email" placeholder="Enter Email ID" matInput>
                            </mat-form-field>
                        </div>
                        <div *ngIf="isUpdate" class="input-group">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Email ID</mat-label>
                                <input formControlName="email" placeholder="Enter Email ID" readonly matInput style="cursor: default;">
                            </mat-form-field>
                        </div>

                      
                        <div class="input-group" *ngIf="!id">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input type="password" formControlName="password" placeholder="Enter Password" matInput>
                            </mat-form-field>
                        </div>
                               
                    </div>

                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input formControlName="lastName" placeholder="Mark" matInput>
                        </mat-form-field>
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Contact</mat-label>
                            <input formControlName="mobileNumber" placeholder="Enter Contact" matInput>
                        </mat-form-field>
        


                        <div class="input-group" *ngIf="!id">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Confirm Password</mat-label>
                                <input type="password" formControlName="confirmPassword" placeholder="Enter Password" matInput>
                            </mat-form-field>
                        </div>

                       
                    </div>
                </div>
                <div class="text-center">
                    <!-- <button class="btn btn-light" style="border:1px solid #BFBFBF; margin-right: 20px;">CLEAR</button> -->
                    <button *ngIf="!isUpdate" [disabled]="Form.invalid || isPasswordMatch()" class="btn btn-primary">ADD TEACHER</button>
                    <button *ngIf="isUpdate" [disabled]="Form.invalid" class="btn btn-danger">UPDATE TEACHER</button>
                </div>
            </form>
        </div>
    </div>

</div>