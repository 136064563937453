<div class="category">
    <div class="card">
        <div class="back-arrow" style="margin: 25px 25px 0px 60px">
            <div class="row">
                <a style="cursor: pointer;" (click)="onBack()"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 style="margin-bottom: 0px; color: #000000;">COURSE DETAILS</h5>
            </div>
        </div>
        <p style="margin-left: 100px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Students course details</p>

        <div class="review-info">
            <table id="management">
                <thead>
                    <tr>
                        <th>COURSE NAME</th>
                        <th width="320px">DESCRIPTION</th>
                        <th>AMOUNT</th>
                        <th>STATUS</th>
                        <th>TIMING</th>
                        
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of Items">
                        <td>{{item?.detail?.name}}</td>
                        <td>{{item?.detail?.description}}</td>
                        <td>{{item?.detail?.price}}</td>
                        <td>{{item?.status}}</td>
                        <td>{{item?.timing?.day}} {{item?.timing?.time}}</td>
                        <td>
                            <img title="View Courses" style="cursor: pointer;margin-right: 5px" src="assets/images/icons/View.png" alt="" (click)="onCourseProgress(item)">
                            <!-- <button class="btn btn-success" style="background-color: #039889;font-size: 10px;" (click)="onCourseProgress(item)">VIEW
                            COURSE PROGRESS</button> -->
                    </td>
                    </tr>
                    <tr *ngIf="Items.length == 0">
                        <td colspan="100"
                            style="text-align: center">No record found
                        </td>
    
                    </tr>
    
                </tbody>
            </table>
        </div>
    </div>

</div>