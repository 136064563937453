<div class="card">
    <div class="header-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <h5 style="color: black; margin-bottom: 0rem;">TESTIMONIALS MANAGEMENT</h5>
                <p style="color:#6D6E7C; font-size: 14px;">Here you should be able to add/remove/edit testimonials</p>
            </div>
            <div class="col-sm-6">
                <div class="edit-button" style="padding: 5px 40px 0px 0px;">
                    <button class="btn btn-primary" (click)="addTestimonial()">ADD TESTIMONIAL</button>
                </div>
            </div>
        </div>
    </div>


    <div class="review-info">
        <table id="management">
            <thead>
                <tr>
                    <th>PARENT NAME</th>
                    <th width="550px">COMMENT</th>
                    <th>STATUS</th>
                    <th>IMAGE</th>
                    <th>ACTIONS</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Items">
                    <td>{{item?.user?.name?.firstName}} {{item?.user?.name?.lastName}}</td>
                    <td style="white-space: pre-line">{{item?.comment}}</td>
                    <td>
                        <mat-slide-toggle [(ngModel)]="item.otherProps.status.enabled"
                                          (change)="toggleView(item)"></mat-slide-toggle>
                    </td>
                    <td *ngIf="item?.user?.profilePicture"><a [href]="item?.user?.profilePicture?.path" target="_blank"><img style="width: 50px;" [src]="item?.user?.profilePicture?.path" alt=""></a></td>
                    <td *ngIf="!item?.user?.profilePicture">N/A</td>
                    <td>
                        <img title="Edit" style="cursor: pointer;margin-right: 5px" src="assets/images/icons/Edit.png" alt="" (click)="editTestimonial(item)">
                    </td>
                </tr>
                
            </tbody>
        </table>
    </div>

</div>



<div style="padding: 0 30px;float: right;">
    <app-pagination *ngIf="Items.length != 0" 
    (setPage)="setPage($event)" 
    [perPage]="perPage" 
    [totalPages]="totalPages"
    [currentPage]="currentPage"></app-pagination>
</div>