import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AlertDialog } from 'src/app/lib';
import { MainService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-class',
  templateUrl: './add-class.component.html',
  styleUrls: ['./add-class.component.scss']
})
export class AddClassComponent implements OnInit {

  Form: FormGroup;
  indexArray: any = [];

  Medicines: any = [];
  url: string;

  sub: Subscription;
  id:any;
  milestones:any = [];

  constructor(protected fb: FormBuilder, protected dialog: MatDialog, private mainService: MainService, protected _route: ActivatedRoute) { 
    this.milestones = JSON.parse(localStorage.getItem('milestones'))
  }

  ngOnInit(): void {
    this.sub = this._route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      if (this.id) {

      }
    });
    this.Form = this.fb.group({
      millistones: this.fb.array([]),
    });

    if(this.milestones.length > 0){
      this.milestones.forEach(element => {
        let fdForm = this.fb.group({
            title: new FormControl(element.title, Validators.required),
            description: new FormControl(element.description, Validators.required),
        });

        this.OrderItems.push(fdForm);
        this.indexArray.push(element._id);
    });
    }
    else{
      this.onAddMoreItems();
    }
  }
  onBack() {
    window.history.back();
  }
  get OrderItems(): FormArray {
    return this.Form.get('millistones') as FormArray;
  }
  onAddMoreItems() {
    let fdForm = this.fb.group({
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    });

    this.OrderItems.push(fdForm);
  };

  onDeleteOrderItems(i, item): void {
    console.log(item);

    // this.Medicines.forEach(element => {
    //     if (element.id == this.indexArray[i]) {
    //         element.isSelected = false;
    //     }
    // });
    this.indexArray.splice(i, 1);
    this.OrderItems.removeAt(i);
  }
  doSubmitForm() {
    this.url = 'add/course-millistones/' + this.id;
    this.mainService.putData(this.url, this.Form.value).then(response => {
      if (response.code == 200 || response.code == 201) {

        this.onBack();

      }
      else if (response.code == 208) {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.message;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
      else {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
    },
      Error => {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      });

  }
}
