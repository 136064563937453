import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-teacher-form',
  templateUrl: './teacher-form.component.html',
  styleUrls: ['./teacher-form.component.scss']
})
export class TeacherFormComponent implements OnInit {

  sub: Subscription;
  id: any;
  isUpdate: boolean = false;
  Form: FormGroup;
  url: any = "register/teacher";
  addButtonText: string = 'ADD';
  editItem: any[];
  file_name:any = "Choose Image";
  teacherItem: any;

  constructor(private router: Router, private mainService: MainService, protected formbuilder: FormBuilder, protected dialog: MatDialog, protected _route: ActivatedRoute) {
    this.Form = this.formbuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required]],
      mobileNumber: [null, [Validators.required]],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.sub = this._route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      if (this.id) {
        this.Form = this.formbuilder.group({
          firstName: [null, [Validators.required]],
          lastName: [null, [Validators.required]],
          email: [null, [Validators.required]],
          mobileNumber: [null, [Validators.required]],
        })
        this.teacherItem = JSON.parse(localStorage.getItem('teacher'));
        console.log("teacherItem: ",this.teacherItem)
        this.Form.get('firstName').setValue(this.teacherItem.name.firstName);
        this.Form.get('lastName').setValue(this.teacherItem.name.lastName);
        this.Form.get('email').setValue(this.teacherItem.contact.email);
        this.Form.get('mobileNumber').setValue(this.teacherItem.contact.mobileNumber);
        // this.Form.get('password').setValue(this.teacherItem.password);
        this.isUpdate = true;
        this.getItemList();
      }
      else{
        this.Form = this.formbuilder.group({
          firstName: [null, [Validators.required]],
          lastName: [null, [Validators.required]],
          email: [null, [Validators.required]],
          mobileNumber: [null, [Validators.required]],
          password: [null, [Validators.required]],
          confirmPassword: [null, [Validators.required]],
        })
      }
   
    });
  }
  public isPasswordMatch() {
    return this.Form.get('password').value !== this.Form.get('confirmPassword').value;
  }
  getItemList() {
    console.log("UPDATE")
  }

 
  doSubmitForm() {
    var methodName = 'postData';
    
    if (this.isUpdate) {
      methodName = 'patch';
      this.url = 'update/teacher/'+this.teacherItem._id
    }
   
    this.mainService[methodName](this.url, this.Form.value).then(response => {
      if (response.code == 200 || response.code == 201) {
        this.isUpdate = true;
        this.url = 'register/teacher';
        this.addButtonText = 'ADD';
        this.onBack();
      }
      else if (response.code == 208) {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.message;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }

      else if (response.code == 400) {
        console.log("400 bad request console", response)
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.error.data[0].msg;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
      else {
        console.log("ERRORRRRRRR")
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
    },
      Error => {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      });
  }
  onBack() {
    window.history.back();
  }
}
