import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainService, PaginationService } from 'src/app/services';

@Component({
  selector: 'app-view-child',
  templateUrl: './view-child.component.html',
  styleUrls: ['./view-child.component.scss']
})
export class ViewChildComponent implements OnInit {
  
  search: any = '';
  Items: any = [];

  currentPage: any = 1;
  sortBy: any = 'DESC';
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  count: any;
  searchTimer: any;
  sub: Subscription;
  id:any;
  constructor(private router: Router, private mainService: MainService, protected _route: ActivatedRoute, protected paginationService: PaginationService) { }

  ngOnInit(): void {

    this.sub = this._route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      if (this.id) {
        this.getList(1);
      }
    });
  }
  addChild(){
    this.router.navigateByUrl('/main/parents/add-child/'+this.id);
  }
  editChild(item){
    this.router.navigateByUrl('/main/edit-child');
  }
  viewChildDetail(item){
    localStorage.setItem('childData', JSON.stringify(item))
    this.router.navigateByUrl('/main/parents/child/detail');
  }
  setPage(pageDate: any) {
    this.currentPage = pageDate.page;
    this.perPage = pageDate.perPage;
    this.index = this.currentPage;
    this.getList(pageDate.page);
  }
  onBack(){
    window.history.back();
  }
  onSearch()
  {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
          this.getList(1);
      }, 800);
  }

  getList(index: any) {

    var url = 'view/parent/'+ this.id +'?page=' + index + '&perPage=' + this.perPage;

    if (this.search != '') {
      url = url + '&search=' + this.search;
    }
    this.mainService.getList(url).then(response => {
      if (response.code == 200 || response.code === 201) {
        console.log(response.data);
        this.Items = response.data.childrens;


        // this.count = response.data.pagination.count;
        // this.currentPage = response.data.pagination.page;
        // this.pages = this.paginationService.setPagination(response.data.pagination.count, this.currentPage, this.perPage);
        // this.totalPages = this.pages.totalPages;
      }
      else {
        this.Items = [];

      }
    },
      Error => {

      })
  }
}
