import {
    Component,
    OnInit
} from '@angular/core';
import {
    Router,
    ActivatedRoute
} from '@angular/router';
import {
    MainService,
    PaginationService
} from 'src/app/services';
import {
    Subscription
} from 'rxjs';
import {
    MatDialog
} from '@angular/material';
import {
    AlertDialog
} from 'src/app/lib';

@Component({
    selector: 'app-price-plans',
    templateUrl: './price-plans.component.html',
    styleUrls: ['./price-plans.component.scss']
})
export class PricePlansComponent implements OnInit {

    search: any = '';
    Items: any = [];

    currentPage: any = 1;
    sortBy: any = 'DESC';
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    count: any;
    searchTimer: any;
    sub: Subscription;
    id: any;
    constructor(private router: Router, protected dialog: MatDialog, private mainService: MainService, protected _route: ActivatedRoute, protected paginationService: PaginationService) {}

    ngOnInit(): void {
        this.getList(1)
    }

    setPage(pageDate: any) {
        this.currentPage = pageDate.page;
        this.perPage = pageDate.perPage;
        this.index = this.currentPage;
        this.getList(pageDate.page);
    }

    onSearch() {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getList(1);
        }, 800);
    }

    getList(index: any) {
        var url = 'view/course-plans?page=' + index + '&perPage=' + this.perPage;

        if (this.search != '') {
            url = url + '&search=' + this.search;
        }
        this.mainService.getList(url).then(response => {
                if (response.code == 200 || response.code === 201) {
                    console.log(response.data);
                    this.Items = response.data.listing;
                    this.count = response.data.pagination.count;
                    this.currentPage = response.data.pagination.page;
                    this.pages = this.paginationService.setPagination(response.data.pagination.count, this.currentPage, this.perPage);
                    this.totalPages = this.pages.totalPages;
                } else {
                    this.Items = [];

                }
            },
            Error => {

            });
    }

    toggleView(item) {
        console.log(item.otherProps.status.enabled);
        var data = {
            enabled: item.otherProps.status.enabled
        }

        var url = 'update/course-plan/' + item._id + '/status';
        this.mainService.patch(url, data).then(response => {
                if (response.code == 200 || response.code == 201) {

                } else {
                    item.otherProps.status.enabled = !item.otherProps.status.enabled
                }
            },
            Error => {
                let dialogRef = this.dialog.open(AlertDialog, {
                    autoFocus: false
                });
                let cm = dialogRef.componentInstance;
                cm.heading = 'Error';
                cm.message = 'Internal Server Error';
                cm.cancelButtonText = 'Close';
                cm.type = 'error';
            });

    }

    onAdd() {
      this.router.navigateByUrl('/main/price-plans/add');
  }

  onEdit(item) {
      this.router.navigateByUrl('/main/price-plans/update/' + item._id);
  }
}