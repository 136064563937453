<div class="category">
    <div class="card">
        <div class="back-arrow" style="margin: 25px 25px 0px 40px">
            <div class="row">
                <a [routerLink]="[ '/main/teachers' ]"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 style="margin-bottom: 0px; color: #000000;">ADD TEACHER</h5>
            </div>
        </div>
        <p style="margin-left: 80px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Here you can add or edit teacher details</p>

        <div class="noti-details">
            <form>
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input placeholder="Fred" matInput>
                        </mat-form-field>


                        <div class="input-group">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Email ID</mat-label>
                                <input placeholder="Enter Email ID" matInput>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field class="input-width" appearance="outline">
                                    <mat-label>Contact</mat-label>
                                    <input placeholder="Enter Contact" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Education</mat-label>
                                    <mat-select>
                                        <mat-option value="option">BBA</mat-option>
                                        <mat-option value="option">EE</mat-option>
                                        <mat-option value="option">BCS</mat-option>
                                        <mat-option value="option">BA</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>

                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input placeholder="Mark" matInput>
                        </mat-form-field>


                        <div class="input-group">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input placeholder="Enter Password" matInput>
                            </mat-form-field>
                        </div>

                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Area of Interest</mat-label>
                            <input placeholder="Add Area of Interest" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-light" style="border:1px solid #BFBFBF; margin-right: 20px;">CLEAR</button>
                    <button class="btn btn-primary">ADD TEACHER</button>
                </div>
            </form>
        </div>
    </div>

</div>