import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/auth/login/login.component';
import { TemplateComponent } from './template/template.component';
import { DashboradComponent } from './views/dashborad/dashborad.component';
import { TeacherManagementComponent } from './views/teacher-management/teacher-management.component';
import { SettingsComponent } from './views/settings/settings.component';
import { AddTeacherComponent } from './views/teachers/add-teacher/add-teacher.component';
import { CoursesComponent } from './views/courses/courses.component';
import { VouchersComponent } from './views/vouchers/vouchers.component';
import { ParentsComponent } from './views/parents/parents.component';
import { BlogsComponent } from './views/blogs/blogs.component';
import { AddClassComponent } from './views/courses/add-class/add-class.component';
import { TeachersComponent } from './views/teachers/teachers.component';
import { EditTeacherComponent } from './views/teachers/edit-teacher/edit-teacher.component';
import { ViewTeacherPaymentComponent } from './views/teachers/view-teacher-payment/view-teacher-payment.component';
import { ViewChildComponent } from './views/parents/view-child/view-child.component';
import { AddChildComponent } from './views/parents/view-child/add-child/add-child.component';
import { EditChildComponent } from './views/parents/view-child/edit-child/edit-child.component';
import { ViewChildDetailComponent } from './views/parents/view-child/view-child-detail/view-child-detail.component';
import { AuthGuard, MainAuthGuard } from './guards';
import { TestimonialsComponent } from './views/testimonials/testimonials.component';
import { AddTestimonialComponent } from './views/testimonials/add-testimonial/add-testimonial.component';
import { EditTestimonialComponent } from './views/testimonials/edit-testimonial/edit-testimonial.component';
import { AddVoucherComponent } from './views/vouchers/add-voucher/add-voucher.component';
import { EditVoucherComponent } from './views/vouchers/edit-voucher/edit-voucher.component';
import { CourseFormComponent } from './views/courses/course-form.component';
import { TeacherFormComponent } from './views/teachers/teacher-form.component';
import { ViewTeacherDetailComponent } from './views/teachers/view-teacher-detail.component';
import { CourseProgressComponent } from './views/parents/view-child/course-progress/course-progress.component';
import { TestimonialFormComponent } from './views/testimonials/testimonial-form.component';
import { VouchersFormComponent } from './views/vouchers/vouchers-form.component';
import { PricePlansComponent } from './views/price-plans/price-plans.component';
import { PricePlansFormComponent } from './views/price-plans/price-plans-form.component';

const publicRoutes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent }
];

const mainApp: Routes = [

	{ path: '', redirectTo: 'courses', pathMatch: 'full' },
  { path: 'dashboard', component: DashboradComponent },
  /* ------------------Course-Details------------------ */
  { path: 'courses', component: CoursesComponent },

  { path: 'courses/add', component: CourseFormComponent },
  { path: 'courses/update/:id', component: CourseFormComponent },

  { path: 'courses/add-class/:id', component: AddClassComponent },
  /* ------------------Teacher-Details------------------ */
  { path: 'teachers', component: TeachersComponent },
  { path: 'teachers/add', component: TeacherFormComponent },
  { path: 'teachers/update/:id', component: TeacherFormComponent },
  { path: 'teachers/detail/:id', component: ViewTeacherDetailComponent },
  { path: 'teachers/payment/:id', component: ViewTeacherPaymentComponent },

  { path: 'add-teacher', component: AddTeacherComponent },
  { path: 'edit-teacher', component: EditTeacherComponent },
  /* ------------------Parent-Details------------------ */
  { path: 'parents', component: ParentsComponent },
  /* ------------------Child-Details------------------ */
  { path: 'parents/view-child/:id', component: ViewChildComponent },
  { path: 'parents/add-child/:id', component: AddChildComponent },
  { path: 'edit-child', component: EditChildComponent },
  { path: 'parents/child/detail', component: ViewChildDetailComponent },
  { path: 'parents/child/:childId/:courseId', component: CourseProgressComponent },
    /* ------------------Testimonials-Details------------------ */
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'testimonials/add', component: TestimonialFormComponent },
  { path: 'testimonials/update/:id', component: TestimonialFormComponent },
  { path: 'add-testimonial', component: AddTestimonialComponent },
  { path: 'edit-testimonial', component: EditTestimonialComponent },
  { path: 'blogs', component: BlogsComponent },
    /* ------------------Vouchers-Details------------------ */
  { path: 'vouchers', component: VouchersComponent },
  { path: 'vouchers/add', component: VouchersFormComponent },
  { path: 'vouchers/update/:id', component: VouchersFormComponent },

  { path: 'add-voucher', component: AddVoucherComponent },
  { path: 'edit-voucher', component: EditVoucherComponent },
  { path: 'settings', component: SettingsComponent },

  { path: 'price-plans', component: PricePlansComponent},
  { path: 'price-plans/add', component: PricePlansFormComponent },
  { path: 'price-plans/update/:id', component: PricePlansFormComponent },
];

const appRoutes: Routes = [
	{ path: '', redirectTo: '/auth/login', pathMatch: 'full' },
	{ path: 'auth', component: AppComponent, children: publicRoutes, canActivate: [AuthGuard] },
  { path: 'main', component: TemplateComponent, children: mainApp, canActivate: [MainAuthGuard]  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash : true})],
  exports: [RouterModule],
	providers: []
})
export class AppRoutingModule { }
