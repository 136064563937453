<div class="category">
    <div class="card">
        <div class="back-arrow" style="margin: 25px 25px 0px 40px">
            <div class="row">
                <a [routerLink]="[ '/main/courses' ]"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 *ngIf="!isEditable()" style="margin-bottom: 0px; color: #000000; text-transform: uppercase;">ADD Price Plan</h5>
                <h5 *ngIf="isEditable()" style="margin-bottom: 0px; color: #000000; text-transform: uppercase;">UPDATE price plan</h5>
            </div>
        </div>
        <p style="margin-left: 80px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Here you can add or edit price plan details</p>

        <div class="noti-details">
            <form [formGroup]="Form"
            (ngSubmit)="doSubmitForm()">
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Plan Name</mat-label>
                            <input formControlName="name" placeholder="Enter Name" matInput>
                        </mat-form-field>

                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Monthly Price</mat-label>
                            <input formControlName="monthlyPrice" placeholder="Enter Monthly Price" matInput>
                        </mat-form-field>
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Number of Months</mat-label>
                            <input formControlName="numberOfMonths" placeholder="Enter number of months" matInput>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Classes per Month</mat-label>
                            <input formControlName="classesPerMonth" placeholder="Enter classes per month" matInput>
                        </mat-form-field>
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Price per class</mat-label>
                            <input formControlName="pricePerClass" placeholder="Enter price per class" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="text-center">
                    <!-- <a class="btn btn-light" style="border:1px solid #BFBFBF; margin-right: 20px;">CLEAR</a> -->
                    <button *ngIf="!isEditable()" [disabled]="Form.invalid" class="btn btn-primary">ADD Price plan</button>
                    <button *ngIf="isEditable()" [disabled]="Form.invalid" class="btn btn-danger">UPDATE Price plan</button>
                </div>
            </form>
        </div>
    </div>

</div>