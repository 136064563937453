<div class="category">
    <div class="card">
        <div class="back-arrow"
             style="margin: 25px 25px 0px 40px">
            <div class="row">
                <div>
                    <a style="cursor: pointer;"
                       (click)="onBack()"><img src="./assets/images/left-arrow.png"
                             alt=""></a>
                </div>
                <div class="col-sm-9">
                    <h5 style="margin-bottom: 0px; color: #000000;">ADD CLASS</h5>
                </div>
                <div class="col-sm-2">
                    <a (click)="onAddMoreItems()"
                       style="cursor: pointer; float:right;"><img src="./assets/images/add.png"
                             alt=""></a>
                </div>
            </div>
        </div>
        <p style="margin-left: 70px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Here you can add class details</p>

        <div class="noti-details">
            <form [formGroup]="Form"
                  (ngSubmit)="doSubmitForm()">

                <div class="row"
                     formArrayName="millistones"
                     *ngFor="let creds of OrderItems.controls; let i = index">
                    <ng-container [formGroupName]="i">
                        <div class="form-group col-md-6">
                            <mat-form-field class="input-width"
                                            appearance="outline">
                                <mat-label>Title</mat-label>
                                <input formControlName="title"
                                       placeholder="Session 1"
                                       matInput>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="row">
                                <mat-form-field class="input-width"
                                                appearance="outline">
                                    <mat-label>Class Name</mat-label>
                                    <input formControlName="description"
                                           placeholder="Enter Description"
                                           matInput>
                                </mat-form-field>
                                <a *ngIf="OrderItems.controls.length > 1"
                                   (click)="onDeleteOrderItems(i, creds)"
                                   style="cursor: pointer;"><img src="./assets/images/minus.png"
                                         alt=""
                                         style="padding: 20px 0px 0px 15px; width: 42px;"></a>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="text-center">
                    <!-- <button class="btn btn-light" style="border:1px solid #BFBFBF; margin-right: 20px;">CLEAR</button> -->
                    <button [disabled]="Form.invalid"
                            class="btn btn-primary">ADD TO COURSE</button>
                </div>
            </form>
        </div>
    </div>

</div>