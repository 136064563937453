<div class="card">
    <div class="header-wrapper">
        <div class="row">
            <div class="col-sm-6 back-arrow">
                <a style="cursor: pointer;" (click)="onBack()"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 style="color: black; margin-bottom: 0rem;display: inline-block;">CHILD MANAGEMENT</h5>
                <p style="color:#6D6E7C; font-size: 14px;margin-left: 55px;">Here you can view registered parents/students</p>
            </div>
            <div class="col-sm-6">
                <div class="edit-button" style="padding: 5px 40px 0px 0px;">
                    <button class="btn btn-primary" (click)="addChild()">ADD CHILD</button>
                    <!-- <input class="form-control" type="text" placeholder="SEARCH"> -->
                </div>
            </div>
        </div>
    </div>


    <div class="review-info">
        <table id="management">
            <thead>
                <tr>
                    <th>FIRST NAME</th>
                    <th>LAST NAME</th>
                    <th>EMAIL</th>
                    <th>GRADE</th>
                    <th>ACTION</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Items">
                    <td>{{item?.bioData?.name?.firstName}}</td>
                    <td>{{item?.bioData?.name?.lastName}}</td>
                    <td>{{item?.contact?.email}}</td>
                    <td>{{item?.grade}}</td>
                    <td>
                        <!-- <button class="btn btn-success" style="background-color: #53D90B;"
                            (click)="editChild(item)">EDIT</button> -->
                        <!-- <button class="btn btn-danger" style="margin-left: 8px;">DELETE</button> -->
                        <!-- <button class="btn btn-success" style="background-color: #039889; margin-left: 8px;"
                            (click)="viewChildDetail(item)">VIEW DETAILS</button> -->
                        
                            <img title="View" style="cursor: pointer;margin-right: 5px" src="assets/images/icons/View.png" alt="" (click)="viewChildDetail(item)">
                        </td>
                </tr>
                <tr *ngIf="Items.length == 0">
                    <td colspan="100"
                        style="text-align: center">No record found
                    </td>

                </tr>
            </tbody>
        </table>
    </div>

</div>
