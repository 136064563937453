import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-view-teacher-detail',
  templateUrl: './view-teacher-detail.component.html',
  styleUrls: ['./view-teacher-detail.component.scss']
})
export class ViewTeacherDetailComponent implements OnInit {
  search: any = '';
  Items: any = [];

  currentPage: any = 1;
  sortBy: any = 'DESC';
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  count: any;
  searchTimer: any;
  sub: Subscription;
  id:any;

  constructor(private router: Router, private mainService: MainService,protected dialog: MatDialog, protected _route: ActivatedRoute) { }

  ngOnInit(): void {
    // this.Items = JSON.parse(localStorage.getItem('childData')).courses;
    this.sub = this._route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      if (this.id) {
        this.getList(1);

      }
    });
  }
  onBack(){
    window.history.back();
  }
  getList(index: any) {

    var url = 'view/teacher/'+ this.id +'/assign-classes';

    if (this.search != '') {
      url = url + '&search=' + this.search;
    }
    this.mainService.getList(url).then(response => {
      if (response.code == 200 || response.code === 201) {
        console.log(response.data);
        this.Items = response.data;
      }
      else {
        this.Items = [];

      }
    },
      Error => {

      })
  }
}
