<div class="card">
    <div class="header-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <h5 style="color: black; margin-bottom: 0rem;">PRICE PLANS MANAGEMENT</h5>
                <p style="color:#6D6E7C; font-size: 14px;">Here you should be able to add/remove/edit price plans</p>
            </div>
            <div class="col-sm-6">
                <div class="edit-button"
                     style="padding: 5px 40px 0px 0px;">
                    <button class="btn btn-primary"
                            (click)="onAdd()">ADD PRICE PLAN</button>
                    <input class="form-control"
                           type="text"
                           [(ngModel)]="search"
                           (keyup)="onSearch()"
                           placeholder="SEARCH">
                </div>
            </div>
        </div>
    </div>


    <div class="review-info">
        <table id="management">
            <thead>
                <tr>
                    <th>PLAN NAME</th>
                    <th>MONTHLY PRICE</th>
                    <th>MONTHS</th>
                    <th>CLASSES/month</th>
                    <th>PRICE/class</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Items">
                    <td>{{item?.name ? item?.name : 'N/A'}}</td>
                    <td>{{item?.monthlyPrice ? '$ '+item?.monthlyPrice  : 'N/A'}}</td>
                    <td>{{item?.numberOfMonths ? item?.numberOfMonths : 'N/A'}}</td>
                    <td>{{item?.classesPerMonth ? item?.classesPerMonth : 'N/A'}}</td>
                    <td>{{item?.pricePerClass ? '$ '+item?.pricePerClass : 'N/A'}}</td>
                    <td style="min-width: 85px;">
                        <mat-slide-toggle [(ngModel)]="item.otherProps.status.enabled"
                                          (change)="toggleView(item)"></mat-slide-toggle>
                    </td>
                    <td style="min-width: 85px;">
                        <img title="Edit"
                             style="cursor: pointer;margin-right: 5px"
                             src="assets/images/icons/Edit.png"
                             alt=""
                             (click)="onEdit(item)">
                    </td>
                </tr>
                <tr *ngIf="Items.length == 0">
                    <td colspan="100"
                        style="text-align: center">No record found
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

<div style="padding: 0 30px;float: right;">
    <app-pagination *ngIf="Items.length != 0"
                    (setPage)="setPage($event)"
                    [perPage]="perPage"
                    [totalPages]="totalPages"
                    [currentPage]="currentPage"></app-pagination>
</div>