<div class="card">
    <div class="header-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <h5 style="color: black; margin-bottom: 0rem;">PARENTS MANAGEMENT</h5>
                <p style="color:#6D6E7C; font-size: 14px;">Here you can view registered parents/students</p>
            </div>
            <div class="col-sm-6">
                <div class="edit-search"
                     style="padding: 5px 40px 0px 0px;">
                    <input class="form-control"
                           type="text"
                           [(ngModel)]="search"
                           (keyup)="onSearch()"
                           placeholder="SEARCH">
                </div>
            </div>
        </div>
    </div>


    <div class="review-info">
        <table id="management">
            <thead>
                <tr>
                    <th>PARENT NAME</th>
                    <th>EMAIL ID</th>
                    <th>CONTACT</th>
                    <th>ENABLE/DISABLE</th>
                    <th>ACTION</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Items">
                    <td>{{item?.name?.firstName}} {{item?.name?.lastName}}</td>
                    <td>{{item?.contact?.email}}</td>
                    <td>{{item?.contact?.mobileNumber}}</td>
                    <td *ngIf="item.otherProps?.profile">
                        <mat-slide-toggle [(ngModel)]="item.otherProps.profile.enabled"
                                          (change)="toggleView(item)"></mat-slide-toggle>
                    </td>
                    <td *ngIf="!item.otherProps?.profile">
                        Not Verified/No Profile
                    </td>
                    <td>

                        <img title="View Child"
                             style="cursor: pointer;margin-right: 5px"
                             src="assets/images/icons/ViewChild.png"
                             alt=""
                             (click)="viewChild(item)">
                        <!-- <button class="btn btn-success" style="background-color: #53D90B;" (click)="viewChild(item)">VIEW
                            CHILD</button> -->
                        <!-- <button class="btn btn-primary" (click)="addChild()">ADD CHILD</button> -->
                    </td>
                </tr>
                <tr *ngIf="Items.length == 0">
                    <td colspan="100"
                        style="text-align: center">No record found
                    </td>

                </tr>
            </tbody>
        </table>
    </div>

</div>


<div style="padding: 0 30px;float: right;">
    <app-pagination *ngIf="Items.length != 0"
                    (setPage)="setPage($event)"
                    [perPage]="perPage"
                    [totalPages]="totalPages"
                    [currentPage]="currentPage"></app-pagination>
</div>