import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainService, PaginationService } from 'src/app/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AlertDialog } from 'src/app/lib';

@Component({
  selector: 'app-add-child',
  templateUrl: './add-child.component.html',
  styleUrls: ['./add-child.component.scss']
})
export class AddChildComponent implements OnInit {

  sub: Subscription;
  id:any;
  Form: FormGroup;
  url: any = "";
  
  constructor(private router: Router, private mainService: MainService, protected dialog: MatDialog, protected formbuilder: FormBuilder, protected _route: ActivatedRoute, protected paginationService: PaginationService) { 
    this.Form = this.formbuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required]],
      grade: [null, [Validators.required]],
      // password: [null, [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.sub = this._route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      if (this.id) {
        // this.getList(1);
      }
    });
  }
  onBack(){
    window.history.back();
  }
  doSubmitForm() {
    var methodName = 'putData';
    this.url = "add/parent/"+ this.id +"/children"
    this.mainService[methodName](this.url, this.Form.value).then(response => {
      if (response.code == 200 || response.code == 201) {
        this.url = 'register/teacher';
        this.onBack();
      }
      else if (response.code == 208) {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.message;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }

      else if (response.code == 400) {
        console.log("400 bad request console", response)
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.error.data[0].msg;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
      else {
        console.log("ERRORRRRRRR")
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
    },
      Error => {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      });
  }
}
