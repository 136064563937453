<div class="card">
    <div class="header-wrapper">
        <div class="row">

            <div class="col-sm-6">
                <div class="row">
                    <a style="cursor: pointer;" (click)="onBack()"><img src="./assets/images/left-arrow.png" alt=""></a>
                    <h5 style="color: black; margin-bottom: 0rem;">PAYMENT</h5>
                </div>
                <p style="margin-left: 38px; margin-bottom: 2.5rem; color:#6D6E7C; font-size: 14px;">Teacher’s Payment
                    View</p>
            </div>

            <div class="col-sm-6">
                <!-- <div class="edit-button" style="padding-top: 5px;">
                    <button class="btn btn-primary" style="background-color: #0056BD;">CHANGE PASSWORD</button>
                    <button class="btn btn-primary">DISABLE ACCOUNT</button>
                    <button class="btn btn-danger">DELETE</button>
                    <button class="btn btn-success" style="background-color: #53D90B;">EDIT</button>

                </div> -->
            </div>

        </div>
    </div>


    <div style="padding: 0 40px;">
        <div class="row">
            <div class="col-md-12">
                <h6 *ngIf="!isUpdate" style="color: black; margin-bottom: 2rem;">ADD PAYMENT</h6>
                <h6 *ngIf="isUpdate" style="color: black; margin-bottom: 2rem;">UPDATE PAYMENT</h6>
                <div style="padding-right: 40px;">
                    <form [formGroup]="Form" #formDirective="ngForm" (ngSubmit)="doSubmitForm(formDirective)">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <mat-form-field class="input-width" s appearance="outline">
                                    <mat-label>Month</mat-label>
                                    <mat-select formControlName="month">
                                        <mat-option *ngFor="let item of Months" [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            <div class="form-group col-md-6">
                                <mat-form-field class="input-width" appearance="outline">
                                    <mat-label>Year</mat-label>
                                    <mat-select formControlName="year">
                                        <mat-option *ngFor="let item of Years" [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6">
                                <mat-form-field class="input-width" appearance="outline">
                                    <mat-label>Amount</mat-label>
                                    <input type="number" formControlName="amount" placeholder="Enter Amount" matInput>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6">
                                <mat-form-field class="input-width" appearance="outline">
                                    <mat-label>Currency</mat-label>
                                    <mat-select formControlName="currency">
                                        <mat-option *ngFor="let item of Currency" [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="text-center form-button">
                            <a class="btn btn-light"
                                style="border: 1px solid #BFBFBF;margin-right: 20px;width: 150px;font-size: 12px;border-radius: 20px;"
                                (click)="onClear(formDirective)">CLEAR</a>
                            <button *ngIf="!isUpdate" [disabled]="Form.invalid" class="btn btn-primary">ADD
                                PAYMENT</button>
                            <button *ngIf="isUpdate" [disabled]="Form.invalid" class="btn btn-primary"
                                style="background-color: red;">UPDATE PAYMENT</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-12" style="margin-top: 30px;">
                <div class="row">
                    <div class="col-md-10">

                        <h6 style="color: black; padding: 25px 0px 15px 50px;">PAYMENT DETAILS</h6>
                    </div>
                        <div class="form-group col-md-2">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Year</mat-label>
                                <mat-select  (selectionChange)="changeYear($event.value)" [(ngModel)]="year">
                                    <mat-option *ngFor="let item of Years" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                </div>
                <div class="review-info">
                    <table id="management">
                        <thead>
                            <tr>
                                <th>PAYMENT MONTH</th>
                                <th>AMOUNT</th>
                                <th>CURRANCY</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Items">
                                <td>{{item.month}}</td>
                                <td>{{item.amount}}</td>
                                <td>{{item.currency}}</td>
                                <td>
                                    <!-- <button class="btn btn-success" style="background-color: #53D90B;"
                                        (click)="onEdit(item)">EDIT</button> -->

                            <img title="Edit" style="cursor: pointer" src="assets/images/icons/Edit.png" alt="" (click)="onEdit(item)">
                                    <!-- <button class="btn btn-danger" style="margin-left: 5px;">DELETE</button></td> -->
                            </tr>
                            <tr *ngIf="Items.length == 0">
                                <td colspan="100" style="text-align: center">No record found
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div style="padding: 0 30px;float: right;">
                    <app-pagination *ngIf="Items.length != 0" 
                    (setPage)="setPage($event)" 
                    [perPage]="perPage" 
                    [totalPages]="totalPages"
                    [currentPage]="currentPage"></app-pagination>
                </div> -->
            </div>


        </div>
    </div>


</div>