import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
	selector: 'app-course-form',
	templateUrl: './course-form.component.html',
	styleUrls: ['./course-form.component.scss']
})
export class CourseFormComponent implements OnInit
{

	sub: Subscription;
	id: any;
	isUpdate: boolean = false;
	Form: FormGroup;
	url: any = "add/course";
	addButtonText: string = 'ADD';
	editItem: any[];
	file_name: any = "Choose Image";
	courseItem: any;

	constructor(private router: Router, private mainService: MainService, protected formbuilder: FormBuilder, protected dialog: MatDialog, protected _route: ActivatedRoute)
	{
		this.Form = this.formbuilder.group({
			name: [null, [Validators.required]],
			individualCoursePrice: [null, [Validators.required]],
			groupCoursePrice: [null, [Validators.required]],
			description: [null, [Validators.required]],
			duration: [null, [Validators.required]],
			image: [null, [Validators.required]],
			status: ['active', [Validators.required]],
			feature: [false, [Validators.required]],
		})
	}

	ngOnInit(): void
	{
		this.sub = this._route.params.subscribe(params =>
		{
			this.id = params['id'];
			console.log(this.id);
			if (this.id)
			{
				this.courseItem = JSON.parse(localStorage.getItem('course'));
				console.log("courseItem: ", this.courseItem)
				this.Form.get('name').setValue(this.courseItem.name);
				this.Form.get('description').setValue(this.courseItem.description);
				this.Form.get('individualCoursePrice').setValue(this.courseItem.price.individual);
				this.Form.get('groupCoursePrice').setValue(this.courseItem.price.group);
				this.Form.get('duration').setValue(this.courseItem.duration.months);
				this.Form.get('image').setValue(this.courseItem.image.name);

				// this.Form.get('feature').setValue(this.courseItem.image.name);

				if (this.courseItem.otherProps)
				{
					if (typeof this.courseItem.otherProps.feature === 'string')
					{
						if (this.courseItem.otherProps.feature == "true")
						{
							this.Form.get('feature').setValue(true);
						}
						else if (this.courseItem.otherProps.feature == "false")
						{
							this.Form.get('feature').setValue(false);
						}
					}
					else
					{
						this.Form.get('feature').setValue(this.courseItem.otherProps.feature);
					}
				}

				this.file_name = this.courseItem.image.name;
				this.isUpdate = true;
				this.getItemList();
			}
		});
	}
	getItemList()
	{
		console.log("UPDATE")
	}

	onFileChange(event: any)
	{
		let reader: any = new FileReader();
		if (event.target.files && event.target.files.length > 0)
		{
			let file = event.target.files[0];
			this.file_name = file.name;
			console.log("image ", file);
			// console.log(this.imageSend);
			this.Form.get('image').patchValue(file);

			reader.readAsDataURL(file);
			reader.onload = (event: any) =>
			{
				// this.imageShow.push(event.target.result);
				console.log(event.target.result);
			};
		}
	}

	doSubmitForm()
	{
		let formData = new FormData();
		for (var key in this.Form.value)
		{
			formData.append(key, this.Form.value[key]);
		}
		var methodName = 'postData';

		if (this.isUpdate)
		{
			methodName = 'patch';
			this.url = 'update/course/' + this.courseItem._id
		}

		this.mainService[methodName](this.url, formData).then(response =>
		{
			if (response.code == 200 || response.code == 201)
			{
				this.isUpdate = true;
				this.url = 'add/course';
				this.addButtonText = 'ADD';
				this.onBack();
			}
			else if (response.code == 208)
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Warning!';
				cm.message = response.message;
				cm.cancelButtonText = 'Close';
				cm.type = 'error';
			}

			else if (response.code == 400)
			{
				console.log("400 bad request console", response)
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Warning!';
				cm.message = response.error.data[0].msg;
				cm.cancelButtonText = 'Close';
				cm.type = 'error';
			}
			else
			{
				console.log("ERRORRRRRRR")
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = 'Internal Server Error';
				cm.cancelButtonText = 'Close';
				cm.type = 'error';
			}
		},
			Error =>
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = 'Internal Server Error';
				cm.cancelButtonText = 'Close';
				cm.type = 'error';
			});
	}
	onBack()
	{
		window.history.back();
	}
}
