<div class="category">
    <div class="card">
        <div class="back-arrow" style="margin: 25px 25px 0px 40px">
            <div class="row">
                <a style="cursor: pointer;" (click)="onBack()"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 style="margin-bottom: 0px; color: #000000;">ADD CHILD</h5>
            </div>
        </div>
        <p style="margin-left: 80px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Here you can add or edit
            child details</p>

        <div class="noti-details">
            <form [formGroup]="Form" (ngSubmit)="doSubmitForm()">
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input formControlName="firstName" placeholder="Enter first name" matInput>
                        </mat-form-field>

                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Child’s Email</mat-label>
                            <input formControlName="email" placeholder="Enter Email ID" matInput>
                        </mat-form-field>

                    </div>

                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input formControlName="lastName" placeholder="Enter last name" matInput>
                        </mat-form-field>

                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Grade</mat-label>
                            <mat-select formControlName="grade">
                                <mat-option value="2-3">2-3</mat-option>
                                <mat-option value="4-6">4-6</mat-option>
                                <mat-option value="7-9">7-9</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                </div>
                <div class="text-center">
                    <!-- <button class="btn btn-light" style="border:1px solid #BFBFBF; margin-right: 20px;">CLEAR</button> -->
                    <button [disabled]="Form.invalid" class="btn btn-primary">ADD CHILD</button>
                </div>
            </form>
        </div>
    </div>

</div>