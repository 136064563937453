import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router) { }
	canActivate() 
	{
		// localStorage.removeItem('LMS-Admin');
		let admin = JSON.parse(localStorage.getItem('LMS-Admin'));
		if (admin == void 0)
		{
			return true;
		}
		else 
		{	
			this.router.navigate(['/main']);
			return false;
		}
	}
}
