export const appConfig =
{
    // Live
    base_url: 'https://lms-api.veroke.com/api/v1/admin/',
    file_url: 'https://lms-api.veroke.com/',
    socket_url: 'https://lms-api.veroke.com/',
    // file_url: 'https://nodeapp.iquition.com/',
    // socket_url: 'https://nodeapp.iquition.com/',


    // Staging
    // base_url: 'http://staging.iquition.com/LMS/api/v1/admin/',
    // file_url: 'http://staging.iquition.com/',
    // socket_url: 'http://staging.iquition.com/',


    base_url_slug: 'partners/',

    default_auth_key: '',
    google_api_key: 'AIzaSyDykNkzGorhQSSUDKkJUzSYFMK04_YeHQ4',

}
