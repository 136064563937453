<div class="card">

    <h5 style="color: black; padding:30px 0px 0px 70px;">PROFILE SETTINGS</h5>
    <p style="color:#6D6E7C; font-size: 14px; padding: 0px 0px 8px 70px;">Here you can change profile settings</p>
    <!-- <hr style="border: 4px solid lightgrey;"> -->

    <div class="teacher-info">
        <form [formGroup]="form">
            <div class="form-group">
                <label>NEW PASSWORD</label>
                <input type="password" formControlName="password" class="form-control" placeholder="Enter Password">

                <label>CONFIRM PASSWORD</label>
                <input type="password" formControlName="confirmPassword" class="form-control"
                    placeholder="Enter Confirm Password">
            </div>

            <button (click)="updatePassword()" [disabled]="form.invalid || isPasswordMatch()"
                class="btn btn-primary">CONFIRM PASSWORD</button>

        </form>
    </div>

</div>