import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { appConfig } from "../config";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboradComponent } from './views/dashborad/dashborad.component';
import { SettingsComponent } from './views/settings/settings.component';
import { TemplateComponent } from './template/template.component';
import { LoginComponent } from './views/auth/login/login.component';
import { ForgetPasswordComponent } from './views/auth/forget-password/forget-password.component';
import { TeacherManagementComponent } from './views/teacher-management/teacher-management.component';
import { AddTeacherComponent } from './views/teachers/add-teacher/add-teacher.component';
import { CoursesComponent } from './views/courses/courses.component';
import { ParentsComponent } from './views/parents/parents.component';
import { VouchersComponent } from './views/vouchers/vouchers.component';
import { BlogsComponent } from './views/blogs/blogs.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './material.module';
import { AddClassComponent } from './views/courses/add-class/add-class.component';
import { TeachersComponent } from './views/teachers/teachers.component';
import { EditTeacherComponent } from './views/teachers/edit-teacher/edit-teacher.component';
import { ViewTeacherPaymentComponent } from './views/teachers/view-teacher-payment/view-teacher-payment.component';
import { ViewChildComponent } from './views/parents/view-child/view-child.component';
import { AddChildComponent } from './views/parents/view-child/add-child/add-child.component';
import { EditChildComponent } from './views/parents/view-child/edit-child/edit-child.component';
import { ViewChildDetailComponent } from './views/parents/view-child/view-child-detail/view-child-detail.component';
import { TestimonialsComponent } from './views/testimonials/testimonials.component';
import { AddTestimonialComponent } from './views/testimonials/add-testimonial/add-testimonial.component';
import { EditTestimonialComponent } from './views/testimonials/edit-testimonial/edit-testimonial.component';
import { AddVoucherComponent } from './views/vouchers/add-voucher/add-voucher.component';
import { EditVoucherComponent } from './views/vouchers/edit-voucher/edit-voucher.component';


import { ApiLoaderService } from "./lib/api-loader/api-loader.service";
import { ApiLoaderInterceptorService } from "./lib/api-loader/api-loader.interceptor.service";
import { ApiLoaderComponent } from "./lib/api-loader/api-loader.component";

// Services
import { AuthService, MainService, PaginationService, BaseLoaderService } from './services';

// Lib
import { FilterDatePipe, AlertDialog, PaginationComponent } from './lib';
import { AuthGuard, MainAuthGuard } from './guards';
import { AppsGuard } from './guards/apps.guard';
import { PushNotificationService } from './lib/push-notification/push-notification.service';
import { SocketService } from './lib/socket.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CourseFormComponent } from './views/courses/course-form.component';
import { MatDialogModule } from '@angular/material';
import { TeacherFormComponent } from './views/teachers/teacher-form.component';
import { ViewTeacherDetailComponent } from './views/teachers/view-teacher-detail.component';
import { CourseProgressComponent } from './views/parents/view-child/course-progress/course-progress.component';
import { TestimonialFormComponent } from './views/testimonials/testimonial-form.component';
import { VouchersFormComponent } from './views/vouchers/vouchers-form.component';
import { PricePlansComponent } from './views/price-plans/price-plans.component';
import { PricePlansFormComponent } from './views/price-plans/price-plans-form.component';

@NgModule({
  declarations: [
    AlertDialog,
    FilterDatePipe,
    ApiLoaderComponent,
    AppComponent,
    DashboradComponent,
    TeacherFormComponent,
    TestimonialFormComponent,
    SettingsComponent,
    TemplateComponent,
    LoginComponent,
    ForgetPasswordComponent,
    TeacherManagementComponent,
    ViewTeacherDetailComponent,
    CourseProgressComponent,
    AddTeacherComponent,
    CoursesComponent,
    ParentsComponent,
    VouchersComponent,
    VouchersFormComponent,
    BlogsComponent,
    AddClassComponent,
    TeachersComponent,
    EditTeacherComponent,
    PaginationComponent,
    ViewTeacherPaymentComponent,
    ViewChildComponent,
    AddChildComponent,
    EditChildComponent,
    ViewChildDetailComponent,
    TestimonialsComponent,
    AddTestimonialComponent,
    EditTestimonialComponent,
    AddVoucherComponent,
    EditVoucherComponent,
    CourseFormComponent,
    PricePlansComponent,
    PricePlansFormComponent
  ],
  imports: [
    MatDialogModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    MainService,
    MainAuthGuard,
    AppsGuard,
    PaginationService,
    BaseLoaderService,
    PushNotificationService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiLoaderInterceptorService,
      multi: true
    },
    SocketService,
    ApiLoaderService
    // { provide: OWL_DATE_TIME_FORMATS, useValue: 'fr' },
    // {provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true },
  ],
  entryComponents: [
    AlertDialog,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
