import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  failureAlert: boolean;
  btnLoading: boolean;
  alertMsg: string;
  theLoginForm: FormGroup;

  constructor(private router:Router, private authService: AuthService, protected formbuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.theLoginForm = this.formbuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.minLength(5), Validators.required]],
      // role_type: ['partner']
  })
  }
  
  signIn(){
    // this.router.navigateByUrl('/main/dashboard');
    this.authService.login(this.theLoginForm.value).then(response => {
      console.log(response);
      if (response.code == 200) {
          console.log(response);
          this.router.navigate(['/main']);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          /* if (response.data.name == 2)
           {
           this.router.navigate(['/main']);
           }
           else
           {
           this.router.navigate(['/main/merchants']);
           }*/

      }
      else {
          this.btnLoading = false;
          this.failureAlert = true;
          // this.alertMsg = response.message;
          this.alertMsg = "Email or Password is incorrect, try again or click on forgot password to reset it.";

          // setTimeout(function () {
          //     this.failureAlert = false;
          // }.bind(this), 2500);
      }
  },
  Error => {
      // // console.log("LOGIN: ",Error)
      this.btnLoading = false;
      this.failureAlert = true;
      this.alertMsg = "Email or Password is incorrect, try again or click on forgot password to reset it.";

      setTimeout(function () {
          this.failureAlert = false;
      }.bind(this), 3000);
  });
  }
}

