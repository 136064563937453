import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainService, PaginationService } from 'src/app/services';
import { MatDialog } from '@angular/material';
import { AlertDialog } from 'src/app/lib';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-view-teacher-payment',
  templateUrl: './view-teacher-payment.component.html',
  styleUrls: ['./view-teacher-payment.component.scss']
})
export class ViewTeacherPaymentComponent implements OnInit {
  
  search: any = '';
  Items: any = [];
  url: any = "";
  isUpdate: boolean = false;
  currentPage: any = 1;
  sortBy: any = 'DESC';
  totalPages: number;
  Form: FormGroup;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  count: any;
  searchTimer: any;
  sub: Subscription;
  id:any;
  addButtonText: string = 'ADD';
  Months: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  Years: any = [2018,2019,2020,2021];
  Currency: any = ['dollar', 'pkr']
  paymentId: any;
  year: any = 2020;
  constructor(private router: Router, private mainService: MainService, protected formbuilder: FormBuilder,protected dialog: MatDialog, protected _route: ActivatedRoute, protected paginationService: PaginationService) { }

  ngOnInit(): void {

    this.Form = this.formbuilder.group({
      month: [null, [Validators.required]],
      year: [null, [Validators.required]],
      amount: [null, [Validators.required]],
      currency: [null, [Validators.required]],
    })

    this.sub = this._route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      if (this.id) {
        this.getList(1);

      }
    });
  }
  setPage(pageDate: any) {
    this.currentPage = pageDate.page;
    this.perPage = pageDate.perPage;
    this.index = this.currentPage;
    this.getList(pageDate.page);
  }

  onEdit(item){
    this.paymentId = item._id;
    this.Form.patchValue(item);
    this.isUpdate = true;
  }
  onClear(formDirective:FormGroupDirective){
    this.paymentId = null;
    this.isUpdate = false;
    formDirective.resetForm()
    this.Form.reset();
  }

  doSubmitForm(formDirective:FormGroupDirective) {
    var methodName ;

    if (this.isUpdate) {
      methodName = 'patch';
      this.url = 'update/teacher/'+ this.id +'/monthly-payment/' + this.paymentId;
    }
    else{
      methodName = 'putData';
      this.url = 'add/teacher/'+ this.id +'/monthly-payment'
    }
   
    this.mainService[methodName](this.url, this.Form.value).then(response => {
      if (response.code == 200 || response.code == 201) {
        this.isUpdate = true;
        this.url = 'add/teacher/'+ this.id +'/monthly-payment'
        this.addButtonText = 'ADD';
        this.year = this.Form.get('year').value;
        this.getList(1);

        this.paymentId = null;
        this.isUpdate = false;
        formDirective.resetForm()
        this.Form.reset();
        
      }
      else if (response.code == 208) {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.message;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }

      else if (response.code == 400) {
        console.log("400 bad request console", response)
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.error.data[0].msg;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
      else {
        console.log("ERRORRRRRRR")
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
    },
      Error => {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      });
  }
  changeYear(val){
    this.year = val;
    this.getList(1)
  }
  getList(index: any) {

    var url = 'view/teacher/'+ this.id +'/monthly-payment?year='+this.year;
    if (this.search != '') {
      url = url + '&search=' + this.search;
    }
    this.mainService.getList(url).then(response => {
      if (response.code == 200 || response.code === 201) {
        console.log(response.data);
        this.Items = response.data.listing;


        // this.count = response.data.pagination.count;
        // this.currentPage = response.data.pagination.page;
        // this.pages = this.paginationService.setPagination(response.data.pagination.count, this.currentPage, this.perPage);
        // this.totalPages = this.pages.totalPages;
      }
      else {
        this.Items = [];

      }
    },
      Error => {

      })
  }

  onBack() {
    window.history.back();
  }
}
