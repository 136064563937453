import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-child-detail',
  templateUrl: './view-child-detail.component.html',
  styleUrls: ['./view-child-detail.component.scss']
})
export class ViewChildDetailComponent implements OnInit {
  Items:any = [];
  id: any;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.Items = JSON.parse(localStorage.getItem('childData')).courses;
    this.id = JSON.parse(localStorage.getItem('childData'))._id;
  }

  onCourseProgress(item){
    this.router.navigateByUrl('/main/parents/child/'+ this.id +'/'+ item._id);
  }
  onBack(){
    window.history.back();
  }
}
