<div class="category">
    <div class="card">
        <div class="back-arrow" style="margin: 25px 25px 0px 40px">
            <div class="row">
                <a [routerLink]="[ '/main/testimonials' ]"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 style="margin-bottom: 0px; color: #000000;">UPDATE TESTIMONIAL</h5>
            </div>
        </div>
        <p style="margin-left: 80px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Here you can update testimonial details</p>

        <div class="noti-details">
            <form>
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Name</mat-label>
                            <input placeholder="Ashly Smith" matInput>
                        </mat-form-field>

                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Picture</mat-label>
                            <input placeholder="Add Picture (Optional)" matInput>
                            <mat-icon matSuffix><img src="./assets/images/attach.png" alt=""></mat-icon>
                        </mat-form-field>

                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Comment</mat-label>
                            <textarea placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit." rows="4" matInput></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="text-center mb-5">
                    <button class="btn btn-light" style="border:1px solid #BFBFBF; margin-right: 20px; width: 160px;">CLEAR</button>
                    <button class="btn btn-danger">UPDATE TESTIMONIAL</button>
                </div>
            </form>
        </div>
    </div>

</div>