import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-vouchers-form',
  templateUrl: './vouchers-form.component.html',
  styleUrls: ['./vouchers-form.component.scss']
})
export class VouchersFormComponent implements OnInit {

  sub: Subscription;
  id: any;
  isUpdate: boolean = false;
  Form: FormGroup;
  url: any = "create/voucher";
  addButtonText: string = 'ADD';
  editItem: any[];
  file_name: any = "Choose Image";
  vouchersItem: any;

  constructor(private router: Router, private mainService: MainService, protected formbuilder: FormBuilder, protected dialog: MatDialog, protected _route: ActivatedRoute) {
    this.Form = this.formbuilder.group({
      title: [null, [Validators.required]],
      code: [null, [Validators.required]],
      discount: [null, [Validators.required]],
      expiryTime: [null, [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.sub = this._route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      if (this.id) {
        this.vouchersItem = JSON.parse(localStorage.getItem('vouchers'));
        console.log("vouchersItem: ", this.vouchersItem)
        this.Form.get('title').setValue(this.vouchersItem.title);
        this.Form.get('code').setValue(this.vouchersItem.code);
        this.Form.get('discount').setValue(this.vouchersItem.discount);
        let t = new Date(this.vouchersItem.expiryTime)
        console.log("---------t----------", t)
        this.Form.get('expiryTime').setValue(t);
        // this.Form.get('password').setValue(this.vouchersItem.password);
        this.isUpdate = true;
        this.getItemList();
      }
    });
  }
  getItemList() {
    console.log("UPDATE")
  }


  doSubmitForm() {

    var methodName = 'postData';

    if (this.isUpdate) {
      methodName = 'patch';
      this.url = 'update/voucher/' + this.vouchersItem._id
    }
    console.log(this.Form.get('expiryTime').value);
    let time = moment(this.Form.get('expiryTime').value).valueOf();
    this.Form.get('expiryTime').setValue(time);   


    this.mainService[methodName](this.url, this.Form.value).then(response => {
      if (response.code == 200 || response.code == 201) {
        this.isUpdate = true;
        this.url = 'create/voucher';
        this.addButtonText = 'ADD';
        this.onBack();
      }
      else if (response.code == 208) {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.message;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }

      else if (response.code == 400) {
        console.log("400 bad request console", response)
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.error.data[0].msg;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
      else {
        console.log("ERRORRRRRRR")
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
    },
      Error => {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      });
  }
  onBack() {
    window.history.back();
  }
}
