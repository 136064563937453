
    <div class="card">
        <div class="header-wrapper">
            <div class="row">
                <div class="col-sm-6">
                    <h5 style="color: black; margin-bottom: 0rem;">TEACHER MANAGEMENT</h5>
                    <p style="color:#6D6E7C; font-size: 14px;">Here you should be able to add/remove/edit teachers</p>
                </div>
                <div class="col-sm-6">
                    <div class="edit-button" style="padding: 5px 40px 0px 0px;">
                        <button class="btn btn-primary" (click)="addTeacher()">ADD TEACHER</button>
                        <input class="form-control" type="text" [(ngModel)]="search"
                        (keyup)="onSearch()" placeholder="SEARCH">
                    </div>
                </div>
            </div>
        </div>


        <div class="review-info">
            <table id="management">
                <thead>
                    <tr>
                        <th>FIRST NAME</th>
                        <th>LAST NAME</th>
                        <th>EMAIL ID</th>
                        <th>CONTACT</th>
                        <th>STATUS</th>
                        <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of Items">
                        <td>{{item?.name?.firstName}}</td>
                        <td>{{item?.name?.lastName}}</td>
                        <td>{{item?.contact?.email}}</td>
                        <td>{{item?.contact?.mobileNumber}}</td>
                        <td>
                            <mat-slide-toggle [(ngModel)]="item.otherProps.profile.enabled"
                                              (change)="toggleView(item)"></mat-slide-toggle>
                        </td>
                        <td>
                            <!-- <button class="btn btn-success" style="background-color: #53D90B;" (click)="editTeacher(item)">EDIT</button> -->
                            <img title="Edit" style="cursor: pointer" src="assets/images/icons/Edit.png" alt="" (click)="editTeacher(item)">
                            <!-- <button class="btn btn-danger" style="margin-left: 8px;">DELETE</button> -->

                            <img title="View" style="cursor: pointer;;margin:0 5px" src="assets/images/icons/View.png" alt="" (click)="viewTeacher(item)">
                            <img title="Payment" style="cursor: pointer;" src="assets/images/icons/payment.png" alt="" (click)="viewTe(item)">
                            <!-- <button class="btn btn-success" style="background-color: #039889; margin-left: 8px;" (click)="viewTe(item)">VIEW</button> -->
                        </td>
                    </tr>
                    <tr *ngIf="Items.length == 0">
                        <td colspan="100"
                            style="text-align: center">No record found
                        </td>
    
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

    <div style="padding: 0 30px;float: right;">
        <app-pagination *ngIf="Items.length != 0" 
        (setPage)="setPage($event)" 
        [perPage]="perPage" 
        [totalPages]="totalPages"
        [currentPage]="currentPage"></app-pagination>
    </div>