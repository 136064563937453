import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  public form: FormGroup;
  public passwordMatch = false;
  constructor(private formBuilder: FormBuilder, private mainService: MainService, private router: Router) { }

  public ngOnInit(): void {
    this.createForm();
  }

  public updatePassword() {
    const data: any = {};
    const user = JSON.parse(localStorage.getItem('LMS-Admin'));
    data.firstName = user.firstName;
    data.lastName = user.lastName;
    data.password = this.form.get('password').value;
    this.mainService.patch('', data).then((res) => {
          if (res.code === 200) {
            this.router.navigate(['main/courses']);
          }
        });
  }

  public isPasswordMatch() {
    return this.form.get('password').value !== this.form.get('confirmPassword').value;
  }

  public createForm(): void {
  this.form = this.formBuilder.group({
    password: [null, [Validators.required, Validators.minLength(6)]],
    confirmPassword: [null, [Validators.required, Validators.minLength(6)]],
  });
  }

}
