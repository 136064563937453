<div class="category">
    <div class="card">
        <div class="back-arrow" style="margin: 25px 25px 0px 60px">
            <div class="row">
                <a style="cursor: pointer;" (click)="onBack()"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 style="margin-bottom: 0px; color: #000000;">COURSE PROGRESS</h5>
            </div>
        </div>
        <p style="margin-left: 100px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Course progress details</p>

        <div class="review-info">
            <table id="management">
                <thead>
                    <tr>
                        <th>NAME</th>
                        <th>STATUS</th>
                        <th>DATE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of Items">
                        <td>{{item?.milliStone}}</td>
                        <td>{{item?.status}}</td>
                        <td>{{item?.date | date : 'yyyy-MM-dd hh:mm a'}}</td>
                    </tr>
                    <tr *ngIf="Items.length == 0">
                        <td colspan="100"
                            style="text-align: center">No record found
                        </td>
    
                    </tr>
    
                </tbody>
            </table>
        </div>
    </div>

</div>