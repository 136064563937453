import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { Router } from '@angular/router';
import { appConfig } from '../../config';
import { PushNotificationOptions, PushNotificationService } from './push-notification/push-notification.service';

@Injectable()
export class SocketService {
    selectedApp: Subject<any> = new Subject<any>();
    userAppData: any;
    userData: Subject<any> = new Subject<any>();
    userDataValue :any;

    socket: any;

    rejectedOrders: any;
    unassignedOrders: any;
    assignedOrders: any;
    pendingOrders: any;
    inProgressOrders: any;
    completedOrders: any;
    totalOrders: any;
    countData: any;

    constructor(private _pushNotificationService: PushNotificationService, private router: Router) {
        // this.isLoadingEvent.next(false);
        this.userAppData = null;
    }
    
    setUserData(val){
        this.userDataValue = val;
        this.userData.next(val);
    }

    getUserData(){
        if(this.userDataValue != null){
            return this.userDataValue;
        }
    }

    setApp(val) {
        this.userAppData = val;
        this.selectedApp.next(val);
    }

    getApp() {
        if (this.userAppData != null) {
            return this.userAppData;
        }
    }
}
