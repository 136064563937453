import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/services';

@Component({
  selector: 'app-course-progress',
  templateUrl: './course-progress.component.html',
  styleUrls: ['./course-progress.component.scss']
})
export class CourseProgressComponent implements OnInit {
  Items:any = [];
  id: any;
  sub: Subscription;
  childId: any;
  courseId: any;
  search: string;
  perPage: any = 100;
  constructor(private _route: ActivatedRoute, private mainService: MainService) { }

  ngOnInit(): void {

    this.sub = this._route.params.subscribe(params => {
      this.childId = params['childId'];
      this.courseId = params['courseId'];
      console.log(this.id);
      if (this.childId && this.courseId) {
        this.getList(1);
      }
    });
    // this.Items = JSON.parse(localStorage.getItem('childData')).courses;
    // this.id = JSON.parse(localStorage.getItem('childData'))._id;
  }


  getList(index: any) {

    var url = 'view/student-course-progress?studentId='+ this.childId +'&courseId='+ this.courseId +'&page=' + index + '&perPage=' + this.perPage;

    if (this.search != '') {
      url = url + '&search=' + this.search;
    }
    this.mainService.getList(url).then(response => {
      if (response.code == 200 || response.code === 201) {
        console.log(response.data);
        this.Items = response.data;


        // this.count = response.data.pagination.count;
        // this.currentPage = response.data.pagination.page;
        // this.pages = this.paginationService.setPagination(response.data.pagination.count, this.currentPage, this.perPage);
        // this.totalPages = this.pages.totalPages;
      }
      else {
        this.Items = [];

      }
    },
      Error => {

      })
  }

  onBack(){
    window.history.back();
  }
}
