<div class="category">
    <div class="card">
        <div class="back-arrow" style="margin: 25px 25px 0px 40px">
            <div class="row">
                <a [routerLink]="[ '/main/vouchers' ]"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 *ngIf="!isUpdate" style="margin-bottom: 0px; color: #000000;">ADD VOUCHER</h5>
                <h5 *ngIf="isUpdate" style="margin-bottom: 0px; color: #000000;">UPDATE VOUCHER</h5>
            </div>
        </div>
        <p style="margin-left: 80px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Here you can add or edit Voucher details</p>

        <div class="noti-details">
            <form [formGroup]="Form"
            (ngSubmit)="doSubmitForm()">
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Title</mat-label>
                            <input formControlName="title" placeholder="Enter Title" matInput>
                        </mat-form-field>


                        <div class="input-group">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Code</mat-label>
                                <input formControlName="code" placeholder="Enter Code" matInput>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Discount (Percentage)</mat-label>
                            <input type="number" formControlName="discount" placeholder="Enter Discount in Percentage" matInput>
                        </mat-form-field>

                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Expiry Time</mat-label>
                            <input matInput formControlName="expiryTime" placeholder="Enter Expiry Time" readonly [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>

                        <!-- <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Expiry Time</mat-label>
                            <input formControlName="expiryTime" placeholder="Enter Expiry Time" matInput>
                        </mat-form-field> -->
        


                        <!-- <div class="input-group">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input type="password" formControlName="password" placeholder="Enter Password" matInput>
                            </mat-form-field>
                        </div> -->

                       
                    </div>
                </div>
                <div class="text-center">
                    <!-- <button class="btn btn-light" style="border:1px solid #BFBFBF; margin-right: 20px;">CLEAR</button> -->
                    <button *ngIf="!isUpdate" [disabled]="Form.invalid" class="btn btn-primary">ADD VOUCHER</button>
                    <button *ngIf="isUpdate" [disabled]="Form.invalid" class="btn btn-danger">UPDATE VOUCHER</button>
                </div>
            </form>
        </div>
    </div>

</div>