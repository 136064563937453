import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainService, PaginationService } from 'src/app/services';
import { MatDialog } from '@angular/material';
import { AlertDialog } from 'src/app/lib';

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss']
})
export class VouchersComponent implements OnInit {
  
  search: any = '';
  Items: any = [];

  currentPage: any = 1;
  sortBy: any = 'DESC';
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  count: any;
  searchTimer: any;
  sub: Subscription;
  id:any;
  constructor(private router: Router, private mainService: MainService,protected dialog: MatDialog, protected _route: ActivatedRoute, protected paginationService: PaginationService) { }

  ngOnInit(): void {
    this.getList(1);
  }
  addVoucher(){
    this.router.navigateByUrl('/main/vouchers/add');
  }
  editVoucher(item){
    localStorage.setItem('vouchers', JSON.stringify(item))
    this.router.navigateByUrl('/main/vouchers/update/'+item._id);
  }
  setPage(pageDate: any) {
    this.currentPage = pageDate.page;
    this.perPage = pageDate.perPage;
    this.index = this.currentPage;
    this.getList(pageDate.page);
  }
  onSearch()
  {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
          this.getList(1);
      }, 800);
  }
  onDelete(item){
    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
					let cm = dialogRef.componentInstance;
          cm.heading = 'Alert';
          cm.methodType = 'delete'
          cm.methodName = 'delete/voucher/' + item._id
					cm.message = 'No Rider Found';
					cm.cancelButtonText = 'Ok';
          cm.type = 'ask';
          dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
                this.getList(1);
            }
        });
          
  }

  onDateFormat(item){
    return new Date(item.expiryTime)
  }
  getList(index: any) {

    var url = 'view/vouchers?page=' + index + '&perPage=' + this.perPage;

    if (this.search != '') {
      url = url + '&search=' + this.search;
    }
    this.mainService.getList(url).then(response => {
      if (response.code == 200 || response.code === 201) {
        console.log(response.data);
        this.Items = response.data.listing;


        this.count = response.data.pagination.count;
        this.currentPage = response.data.pagination.page;
        this.pages = this.paginationService.setPagination(response.data.pagination.count, this.currentPage, this.perPage);
        this.totalPages = this.pages.totalPages;
      }
      else {
        this.Items = [];

      }
    },
      Error => {

      })
  }
}
