import { Component, OnInit } from '@angular/core';
import { MainService } from '../services';


declare var $: any;

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})

export class TemplateComponent implements OnInit {
  adminClick: boolean;

  constructor(protected mainApiService: MainService ) { }

  ngOnInit(): void {
    
    $(".menu-trigger").on("click", function (e: any) {
      e.preventDefault();
      var $this = $(this);
      $this.toggleClass("active");
      $this.parents(".sidebar").toggleClass("opened");
      $this.parents(".sidebar").find(".menu-name").toggleClass("opened");
      $(".main-content-router").toggleClass("opened");
      $(".main_content").toggleClass("opened");
      

  });
  }

  onLogout(): void {
    this.mainApiService.onLogout().then(result => {
        if (result.code === 200) {
            localStorage.removeItem('LMS-Admin');

            this.adminClick = false;
            window.location.reload();
        }
        else {

        }
    });
}
}
