<div class="card">
    <div class="header-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <h5 style="color: black; margin-bottom: 0rem;">COURSES MANAGEMENT</h5>
                <p style="color:#6D6E7C; font-size: 14px;">Here you should be able to add/remove/edit course</p>
            </div>
            <div class="col-sm-6">
                <div class="edit-button"
                     style="padding: 5px 40px 0px 0px;">
                    <button class="btn btn-primary"
                            (click)="addCourse()">ADD COURSE</button>
                    <input class="form-control"
                           type="text"
                           [(ngModel)]="search"
                           (keyup)="onSearch()"
                           placeholder="SEARCH">
                </div>
            </div>
        </div>
    </div>


    <div class="review-info">
        <table id="management">
            <thead>
                <tr>
                    <th>COURSE NAME</th>
                    <th>PICTURE</th>
                    <th width="400px">DESCRIPTION</th>
                    <th>INDIVIDUAL PRICE/month</th>
                    <th>GROUP PRICE/month</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Items">
                    <td>{{item?.name ? item?.name : 'N/A'}}</td>
                    <td>
                        <!-- {{item?.image?.name ? item?.image?.name : 'N/A'}} -->
                        <img [src]="item?.image?.path"
                             width="50"
                             alt="image">

                    </td>
                    <td>
                        <div style="padding: 10px;">
                            {{item?.description ? item?.description : 'N/A'}}
                        </div>
                    </td>
                    <td>{{item?.price?.individual ? '$ '+item?.price?.individual : 'N/A'}}</td>
                    <td>{{item?.price?.group ? '$ '+item?.price?.group : 'N/A'}}</td>
                    <td style="min-width: 85px;">
                        <mat-slide-toggle [(ngModel)]="item.courseStatus"
                                          (change)="toggleView(item)"></mat-slide-toggle>
                    </td>
                    <td style="min-width: 85px;">
                        <!-- <button class="btn btn-success" style="background-color: #53D90B;margin: 3px 0;"
                            (click)="editCourse(item)">EDIT</button> -->
                        <img title="Edit"
                             style="cursor: pointer;margin-right: 5px"
                             src="assets/images/icons/Edit.png"
                             alt=""
                             (click)="editCourse(item)">
                        <!-- <button class="btn btn-danger" style="margin: 3px 8px;">DELETE</button> -->
                        <img title="Add Class"
                             style="cursor: pointer;margin-right: 5px"
                             src="assets/images/icons/AddClass.png"
                             alt=""
                             (click)="addClass(item)">
                        <!-- <button class="btn btn-primary" style="margin: 3px;" (click)="addClass(item)">ADD
                            CLASS</button> -->
                    </td>
                </tr>
                <tr *ngIf="Items.length == 0">
                    <td colspan="100"
                        style="text-align: center">No record found
                    </td>

                </tr>
            </tbody>
        </table>
    </div>

</div>

<div style="padding: 0 30px;float: right;">
    <app-pagination *ngIf="Items.length != 0"
                    (setPage)="setPage($event)"
                    [perPage]="perPage"
                    [totalPages]="totalPages"
                    [currentPage]="currentPage"></app-pagination>
</div>