import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services';
import { AlertDialog } from 'src/app/lib';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-price-plans-form',
  templateUrl: './price-plans-form.component.html',
  styleUrls: ['./price-plans-form.component.scss']
})
export class PricePlansFormComponent implements OnInit {

  sub: Subscription;
  id: any = null;
  Form: FormGroup;
  priceItem:any;

  constructor(private mainService: MainService, protected formbuilder: FormBuilder, protected dialog: MatDialog, protected _route: ActivatedRoute) {
    this.Form = this.formbuilder.group({
      name: [null, [Validators.required]],
      monthlyPrice: [null, [Validators.required]],
      numberOfMonths: [null, [Validators.required]],
      classesPerMonth: [null, [Validators.required]],
      pricePerClass: [null, [Validators.required]],
      status: [true, [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.sub = this._route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id);
      if (this.id) {
        this.getSingle();
      }
    });
  }

  getSingle() {
    let url = 'view/course-plan/'+this.id;
    this.mainService.getList(url).then(response => {
      if (response.code == 200 || response.code === 201) {
        this.priceItem = response.data;
        this.populateForm();
      } else {
        this.onBack();
      }
    },
    Error => {

    });
   }

   populateForm() {
        this.Form.get('name').setValue(this.priceItem.name);
        this.Form.get('monthlyPrice').setValue(this.priceItem.monthlyPrice);
        this.Form.get('numberOfMonths').setValue(this.priceItem.numberOfMonths);
        this.Form.get('classesPerMonth').setValue(this.priceItem.classesPerMonth);
        this.Form.get('pricePerClass').setValue(this.priceItem.pricePerClass);
        this.Form.get('status').setValue(this.priceItem.otherProps.status.enabled);
   }

  doSubmitForm() {
    let url = this.isEditable() ? 'update/course-plan/'+this.priceItem._id : 'add/course-plan';
    let method = this.isEditable() ? 'patch' : 'postData';
    let form_data = this.Form.value;
    form_data.status = {enabled: form_data.status};
    this.mainService[method](url, form_data).then(response => {
      if (response.code == 200 || response.code == 201) {
        this.onBack();
      }
      else if (response.code == 208) {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.message;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }

      else if (response.code == 400) {
        console.log("400 bad request console", response)
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Warning!';
        cm.message = response.error.data[0].msg;
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
      else {
        console.log("Error")
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      }
    },
      Error => {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = 'Error';
        cm.message = 'Internal Server Error';
        cm.cancelButtonText = 'Close';
        cm.type = 'error';
      });
  }

  onBack() {
    window.history.back();
  }

  isEditable()
  {
    if (this.id !== null && this.id !== undefined) {
      return true;
    }
    else {
      return false;
    }
  }
}
