<div class="card">
    <div class="header-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <h5 style="color: black; margin-bottom: 0rem;">VOUCHERS MANAGEMENT</h5>
                <p style="color:#6D6E7C; font-size: 14px;">Here you should be able to add/remove/edit Blogs</p>
            </div>
            <div class="col-sm-6">
                <div class="edit-button" style="padding: 5px 40px 0px 0px;">
                    <button class="btn btn-primary" (click)="addVoucher()">ADD VOUCHER</button>
                    <input class="form-control" type="text" [(ngModel)]="search"
                    (keyup)="onSearch()" placeholder="SEARCH">
                </div>
            </div>
        </div>
    </div>


    <div class="review-info">
        <table id="management">
            <thead>
                <tr>
                    <th>TITLE</th>
                    <th>DISCOUNT (%)</th>
                    <th>CODE</th>
                    <th>EXPIRY TIME</th>
                    <th>ACTION</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Items">
                    <td>{{item?.title}}</td>
                    <td>{{item?.discount}}</td>
                    <td>{{item?.code}}</td>
                    <td>{{onDateFormat(item)}}</td>
                    <td>

                        <img title="Edit" style="cursor: pointer;margin-right: 5px" src="assets/images/icons/Edit.png" alt="" (click)="editVoucher(item)">
                        <img title="Delete" style="cursor: pointer;margin-right: 5px" src="assets/images/icons/Delete.png" alt="" (click)="onDelete(item)">
                    </td>
                </tr>
                <tr *ngIf="Items.length == 0">
                    <td colspan="100"
                        style="text-align: center">No record found
                    </td>

                </tr>
            </tbody>
        </table>
    </div>

</div>
<div style="padding: 0 30px;float: right;">
    <app-pagination *ngIf="Items.length != 0" 
    (setPage)="setPage($event)" 
    [perPage]="perPage" 
    [totalPages]="totalPages"
    [currentPage]="currentPage"></app-pagination>
</div>