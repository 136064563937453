<api-loader></api-loader>
<div class="wrapper">
    <div class="sidebar">
        <a class="menu-trigger">
            <!-- <i class="glyphicon glyphicon-barcode"></i> -->
            <img style="width: 35px;margin: 24px;cursor: pointer;"
                 src="assets/images/menu.png"
                 alt="">
        </a>
        <ul>
            <!-- <li routerLinkActive="active">
                <a [routerLink]="[ '/main/dashboard' ]"><img src="./assets/images/home.png" alt=""><span class="menu-name">DASHBOARD</span></a>
            </li> -->
            <li routerLinkActive="active">
                <a [routerLink]="[ '/main/courses' ]"><img src="./assets/images/courses.png"
                         alt=""><span class="menu-name">COURSES</span></a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="[ '/main/teachers' ]"><img src="./assets/images/teacher.png"
                         alt=""><span class="menu-name">TEACHERS</span></a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="[ '/main/parents' ]"><img src="./assets/images/avatar.png"
                         alt=""><span class="menu-name">PARENTS</span></a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="[ '/main/testimonials' ]"><img src="./assets/images/testimonials.png"
                         alt=""><span class="menu-name">TESTIMONIALS</span></a>
            </li>
            <!-- <li routerLinkActive="active">
                <a href="https://iquition.com/blog/wp-admin/" target="_blank"><img src="./assets/images/blog.png" alt=""><span class="menu-name">BLOGS</span></a>
            </li> -->
            <li routerLinkActive="active">
                <a [routerLink]="[ '/main/vouchers' ]"><img src="./assets/images/coupon.png"
                         alt=""><span class="menu-name">VOUCHERS</span></a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="[ '/main/price-plans' ]"><img src="./assets/images/courses.png"
                         alt=""><span class="menu-name">PRICE PLANS</span></a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="[ '/main/settings' ]"><img src="./assets/images/repairing-service.png"
                         alt=""><span class="menu-name">SETTINGS</span></a>
            </li>
        </ul>
        <div class="logout">
            <ul>
                <li><a style="cursor: pointer;"
                       (click)="onLogout()"><i class="fas fa-sign-out-alt"></i><span class="menu-name">LOGOUT</span></a>
                </li>
            </ul>
        </div>
    </div>

    <div class="main_content"
         style="position: fixed;z-index: 11;">

        <div class="header text-center">
            <a style="position: absolute;
            left: 45%;
            top: 50%;
            transform: translate(-50%, -50%);"><img style="width: 145px;"
                     src="./assets/images/logo.png"
                     alt=""></a>
        </div>
    </div>

</div>

<div class="main-content-router">
    <router-outlet></router-outlet>
</div>