<api-loader></api-loader>
<div style="height: 100vh;">
    <!-- <div class="contact-banner" style="height: 28%;">
        <div class="pt-5 pb-1 text-center">

            <div class="pb-5">
                <img style="height: 40px;" src="assets/images/logo.png" alt="">
            </div>

            <h1 style="color: white; font-weight:500; font-size: 20px;"> SIGN IN TO YOUR ACCOUNT </h1>
            <p style="color: white; font-size: 12px">
                Build skills for today, tomorrow, and beyond. Education to future-proof your career.
            </p>
        </div>
    </div> -->
    <div class="banner-bg" style="height: 100%;">
        <div style="position: absolute; left: 50%; top: 43%; transform: translate(-50%, -50%);width:100vw;">
            <div class="pt-5 pb-1 text-center">

                <div class="pb-5">
                    <img style="height: 40px;" src="assets/images/logo.png" alt="">
                </div>
    
                <h1 style="color: white; font-weight:500; font-size: 20px;"> SIGN IN TO YOUR ACCOUNT </h1>
                <!-- <p style="color: white; font-size: 12px">
                    Build skills for today, tomorrow, and beyond. Education to future-proof your career.
                </p> -->
            </div>
            <form [formGroup]="theLoginForm" (ngSubmit)="signIn()">
    
                <div class="row d-flex justify-content-center" style="padding-bottom: 27px; width:100%">
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div class="card ">
                            <div class="card-body">
    
                                <div>
                                    <div class="row pt-1" style="text-align: center;">
    
                                        <div class="form-group col-sm-6 FirstInput">
                                            <input formControlName="email" type="email" class="form-control"
                                                placeholder="Email Address">
                                        </div>
                                        <div class="form-group col-sm-6 SecondInput">
                                            <input formControlName="password" type="password" class="form-control"
                                                placeholder="Password">
                                        </div>
                                    </div>
    
                                </div>
    
                                <span *ngIf="failureAlert" style="color:red; text-align: center;display: block;font-size: 12px;">{{alertMsg}}</span>
                                <div class="pt-3 text-center">
    
                                    <button type="submit" class="btn btn-primary mb-2"
                                        [disabled]="theLoginForm.invalid">SIGN IN</button>
    
                                    <!-- <p style="font-weight:500; color: #0074FF; font-size: 12px;">Forgot your password?</p> -->
    
                                </div>
    
                                <!-- <div class="col-md-6 pb-10 ">
                                    <div class="login-or">
                                        <hr class="hr-or">
                                        <span class="span-or">OR</span>
                                    </div>
                                </div>
                                <div style="text-align: center;">
                                    <p class="" style="font-weight: 500; color: #0074FF; font-size: 12px;">Not a Member? Don’t worry!</p>
    
                                    <button class="btn btn-primary">SIGN UP</button>
                                </div> -->
    
    
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- <div class="lower-footer" style="height: 12%;">
        <div class="row mt-4" style="width: 100%">
            <div class="col-md-10" style="text-align: center;">
                <div class="row">
                    <div class="col-sm-2">
                        <h6 style="font-size: 10px;">Sitemap</h6>
                    </div>
                    <div class="col-sm-2">
                        <h6 style="font-size: 10px;">Privacy Policy</h6>
                    </div>
                    <div class="col-sm-2">
                        <h6 style="font-size: 10px;">Terms and Condtion</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="lower-heading">
                    <h6>Copyrights@2020</h6>
                </div>
            </div>
        </div>
    </div> -->
</div>