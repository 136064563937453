<div class="category">
    <div class="card">
        <div class="back-arrow"
             style="margin: 25px 25px 0px 40px">
            <div class="row">
                <a [routerLink]="[ '/main/courses' ]"><img src="./assets/images/left-arrow.png"
                         alt=""></a>
                <h5 *ngIf="!isUpdate"
                    style="margin-bottom: 0px; color: #000000;">ADD COURSE</h5>
                <h5 *ngIf="isUpdate"
                    style="margin-bottom: 0px; color: #000000;">UPDATE COURSE</h5>
            </div>
        </div>
        <p style="margin-left: 80px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Here you can add or edit
            course details</p>

        <div class="noti-details">
            <form [formGroup]="Form"
                  (ngSubmit)="doSubmitForm()">
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width"
                                        appearance="outline">
                            <mat-label>Course Name</mat-label>
                            <input formControlName="name"
                                   placeholder="Enter Name"
                                   matInput>
                        </mat-form-field>


                        <!-- <div class="input-group">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Image</mat-label>
                                <input placeholder="Add Image" matInput>
                                <mat-icon matSuffix><img src="./assets/images/attach.png" alt=""></mat-icon>
                            </mat-form-field>
                        </div> -->

                        <div class="image-card">
                            <input style="width: 100%; opacity: -1;z-index: 9;"
                                   type="file"
                                   accept=".png,.jpg,.jpeg"
                                   (change)="onFileChange($event)"
                                   capture="environment">
                            <span [innerHTML]="file_name"
                                  style="position: absolute;
                                   top: 50%;
                                   left: 14px;
                                padding-right: 57px;
                                   transform: translate(0, -50%);">
                            </span>
                            <img style="position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translate(-50%, -50%);"
                                 src="./assets/images/attach.png"
                                 alt="">
                        </div>
                        <mat-form-field class="input-width"
                                        appearance="outline">
                            <mat-label>Individual Price</mat-label>
                            <input formControlName="individualCoursePrice"
                                   placeholder="Enter Individual Price"
                                   matInput>
                        </mat-form-field>
                        <mat-form-field class="input-width"
                                        appearance="outline">
                            <mat-label>Group Price</mat-label>
                            <input formControlName="groupCoursePrice"
                                   placeholder="Enter Group Price"
                                   matInput>
                        </mat-form-field>

                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width"
                                        appearance="outline">
                            <mat-label>Duration</mat-label>
                            <input formControlName="duration"
                                   placeholder="Enter duration in months"
                                   matInput>
                        </mat-form-field>
                        <mat-form-field class="input-width"
                                        appearance="outline">
                            <mat-label>Description</mat-label>
                            <textarea formControlName="description"
                                      placeholder="Enter Description"
                                      rows="6"
                                      matInput></textarea>
                        </mat-form-field>
                        <div>
                            <mat-checkbox formControlName="feature">Feature</mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <!-- <a class="btn btn-light" style="border:1px solid #BFBFBF; margin-right: 20px;">CLEAR</a> -->
                    <button *ngIf="!isUpdate"
                            [disabled]="Form.invalid"
                            class="btn btn-primary">ADD COURSE</button>
                    <button *ngIf="isUpdate"
                            [disabled]="Form.invalid"
                            class="btn btn-danger">UPDATE COURSE</button>
                </div>
            </form>
        </div>
    </div>

</div>