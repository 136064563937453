
import { Injectable } from '@angular/core';
// import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { appConfig } from '../../config';


import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SocketService } from '../lib/socket.service';


@Injectable()
export class AuthService 
{
    headers: HttpHeaders;
    options: any;
    public auth_key: string;
    public baseUrl: string;

    constructor(private http: HttpClient, private router: Router, protected appSelectorService: SocketService) 
    {
        // var UrbanpointAdmin = JSON.parse(localStorage.getItem('UrbanpointAdmin'));
        // this.auth_key = UrbanpointAdmin && UrbanpointAdmin.auth_key;
        this.baseUrl = appConfig.base_url;

        // var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // headers.append('Authorization', appConfig.default_auth_key);
        // this.options = new RequestOptions({ headers: headers });

        this.headers = new HttpHeaders({ 'Authorization': appConfig.default_auth_key});
        // headers.append('Content-Type', 'application/json');
        // headers.append('Authorization', appConfig.default_auth_key);
        this.options = {headers: this.headers, observe: 'response'};
    }

    public login(formData: any): Promise<any>
    {
        return this.http.post(this.baseUrl + 'login', formData, this.options)
        .toPromise().then((response: any) =>
        {
            console.log(response);
            let result: any = response.body;
            let authKey = result.data.token;
            let id = result.data.user._id;
            let firstName = result.data.user.name.firstName;
            let lastName = result.data.user.name.lastName;
            let email = result.data.user.contact.email;
            let mobileNumber = result.data.user.contact.mobileNumber;

            var val = {
                authKey: authKey,
                id: id,
                firstName: firstName,
                lastName: lastName,
                email: email,
                mobileNumber: mobileNumber,
            }
            this.appSelectorService.setUserData(val);
            localStorage.setItem('LMS-Admin', JSON.stringify(val));

            return result;
        },
        (reason: any) =>
        {
            return reason;
        }).catch(this.handleError);
    }


    // public sendMT(formData: any): Promise<any>
    // {
    //     return this.http.post('http://18.184.164.213/up_qatar/api/v1/subscription/sendMT', formData, this.options)
    //     .toPromise().then((response: any) =>
    //     {
    //         let result: any = response.body;
    //         console.log('Service Call', result);
    //         return result;
    //     },
    //     (reason: any) =>
    //     {
    //         return reason;
    //     }).catch(this.handleError);
    // }

    public forgotPassword(formData: any): Promise<any>
    {
        return this.http.post(this.baseUrl + 'send_email', formData, this.options)
        .toPromise().then((response: any) =>
        {
            let result: any = response.body;
            return result;
        },
        (reason: any) =>
        {
            return reason;
        }).catch(this.handleError);
    }

    public resetPassword(url:any, formData: any): Promise<any>
    {
        return this.http.patch(this.baseUrl + 'update_password/'+ url, formData, this.options)
        .toPromise().then((response: any) =>
        {
            let result: any = response.body;
            return result;
        },
        (reason: any) =>
        {
            return reason;
        }).catch(this.handleError);
    }

    public recoveryCode(formData: any): Promise<any>
    {
        return this.http.post(this.baseUrl + 'verify_code', formData, this.options)
        .toPromise().then((response: any) =>
        {
            let result: any = response.body;
            return result;
        },
        (reason: any) =>
        {
            return reason;
        }).catch(this.handleError);
    }

    public getMethod(params: string): Promise<any>
    {
        return this.http.get(this.baseUrl + params, this.options)
        .toPromise().then((response: any) =>
        {
            if (response.status === 401) 
			{
				localStorage.clear();
				this.router.navigate(['auth/login']);
			} 
			else 
			{
				return response.body;
			}
        },
        (reason: any) =>
        {
            return reason;
        }).catch(this.handleError);
    }

    public handleError(error: any): Promise<any>
    {
        // console.log("handleError = " + error);
        return error;
    }

}

