<div class="category">
    <div class="card">
        <div class="back-arrow" style="margin: 25px 25px 0px 40px">
            <div class="row">
                <a style="cursor: pointer;" (click)="onBack()"><img src="./assets/images/left-arrow.png" alt=""></a>
                <h5 *ngIf="!isUpdate" style="margin-bottom: 0px; color: #000000;">ADD TESTIMONIAL</h5>
                <h5 *ngIf="isUpdate" style="margin-bottom: 0px; color: #000000;">UPDATE TESTIMONIAL</h5>
            </div>
        </div>
        <p style="margin-left: 80px; margin-bottom: 2.5rem; color: #6D6E7C; font-size: 13px;">Here you can add or edit testimonial details</p>

        <div class="noti-details">
            <form [formGroup]="Form"
            (ngSubmit)="doSubmitForm()">
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input formControlName="firstName" placeholder="Enter First Name" matInput>
                        </mat-form-field>

                      
                        <div class="image-card">
                            <input style="width: 100%; opacity: -1;z-index: 9;"
                                   type="file"
                                   accept="image/*"
                                   (change)="onFileChange($event)"
                                   capture="environment">
                            <span [innerHTML]="file_name"
                                  style="position: absolute;
                                   top: 50%;
                                   left: 14px;
                                padding-right: 57px;
                                   transform: translate(0, -50%);">
                            </span>
                            <img style="position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translate(-50%, -50%);"
                                 src="./assets/images/attach.png"
                                 alt="">
                        </div>
                
                    </div>

                    <div class="form-group col-md-6">
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input formControlName="lastName" placeholder="Enter Last Name" matInput>
                        </mat-form-field>
                        <mat-form-field class="input-width" appearance="outline">
                            <mat-label>Comment</mat-label>
                            <textarea formControlName="comment" placeholder="Enter Comment" rows="6" matInput></textarea>
                        </mat-form-field>
                      
                        <!-- <div class="input-group">
                            <mat-form-field class="input-width" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input type="password" formControlName="password" placeholder="Enter Password" matInput>
                            </mat-form-field>
                        </div> -->

                       
                    </div>
                </div>
                <div class="text-center">
                    <!-- <button class="btn btn-light" style="border:1px solid #BFBFBF; margin-right: 20px;">CLEAR</button> -->
                    <button *ngIf="!isUpdate" [disabled]="Form.invalid" class="btn btn-primary">ADD TESTIMONIAL</button>
                    <button *ngIf="isUpdate" [disabled]="Form.invalid" class="btn btn-danger">UPDATE TESTIMONIAL</button>
                </div>
            </form>
        </div>
    </div>

</div>