import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class AppsGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let roles = route.data["roles"] as Array<string>;
        console.log(roles);


        let dowwak = JSON.parse(localStorage.getItem('dowwak_admin'));
        if (dowwak && dowwak.auth_key != null) 
        {
            if(dowwak.role == 'admin')
            {
                return true;
            }
            if (dowwak.role == 'user' && roles[0] == 'all')
            {
                return true;
            }
            else
            {
                return false;
            }
        }
        this.router.navigate(['/auth/login']);
        return false;
    }
}

