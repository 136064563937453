<div *ngIf="methodType != 'delete'" style="min-width: 300px;">

    <div class="content-head clearfix">
        <div style="font-size: 25px;"
             class="title">{{heading}}</div>
    </div>

    <div class="row">
        <div class="col-sm-12" [innerHTML]="message" style="max-height: 400px;overflow: auto;text-transform: capitalize">
            <!-- {{message}} -->
        </div>
        <!--<div *ngIf="showLoading"
             style="text-align: center"
             class="col-sm-12">
            <img *ngIf="isLoading"
                 width="50"
                 height="50"
                 src="assets/images/view-loader.gif"
                 alt="">
        </div>-->
        <div class="col-sm-12"
             style="text-align: right;margin-top: 20px;">

            <button mat-raised-button
                    color="primary"
                    style="margin-right: 5px"
                    class="btn btn-clear"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onCancelClick()">{{cancelButtonText}}</button>

            <button mat-raised-button
                    color="primary"
                    class="btn btn-submit btn-primary"
                    *ngIf="type == 'ask'"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onSubmitClick()">{{submitButtonText}}</button>
        </div>
    </div>
</div>

<div *ngIf="methodType == 'delete'" style="min-width: 300px; text-align: center">

    <img src="assets/images/dowwak-portal/delete-modal.png" alt="">


    <div class="row">
        <div class="col-sm-12" style="max-height: 400px;overflow: auto;margin-bottom: 5px">
            Are you sure you want to delete?
        </div>
        <div class="col-sm-12" style="max-height: 400px;overflow: auto;">
           If this record is deleted, you won't be able to recover it!
        </div>
      <!--  <div *ngIf="showLoading"
             style="text-align: center"
             class="col-sm-12">
            <img *ngIf="isLoading"
                 width="50"
                 height="50"
                 src="assets/images/view-loader.gif"
                 alt="">
        </div>-->
        <div class="col-sm-12"
             style="margin-top: 30px;">

            <button mat-raised-button
                    color="primary"
                    style="background: #CBCBCB; color: white;margin-right: 50px;"
                    class="btn btn-clear"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onCancelClick()">CANCEL</button>

            <button mat-raised-button
                    color="primary"
                    style="background: #E51607"
                    class="btn btn-submit btn-primary"
                    *ngIf="type == 'ask'"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onSubmitClick()">DELETE</button>
        </div>
    </div>
</div>