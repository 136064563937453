import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService, PaginationService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { AlertDialog } from 'src/app/lib';

@Component({
	selector: 'app-courses',
	templateUrl: './courses.component.html',
	styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit
{

	search: any = '';
	Items: any = [];

	currentPage: any = 1;
	sortBy: any = 'DESC';
	totalPages: number;
	pages: any;
	perPage: number = 10;
	index: any = 1;
	count: any;
	searchTimer: any;
	sub: Subscription;
	id: any;
	constructor(private router: Router, protected dialog: MatDialog, private mainService: MainService, protected _route: ActivatedRoute, protected paginationService: PaginationService) { }

	ngOnInit(): void
	{

		localStorage.removeItem('milestones');
		localStorage.removeItem('course');
		this.getList(1)
	}
	addCourse()
	{
		this.router.navigateByUrl('/main/courses/add');
	}
	editCourse(item)
	{
		localStorage.setItem('course', JSON.stringify(item))
		this.router.navigateByUrl('/main/courses/update/' + item._id);
	}
	addClass(item)
	{
		localStorage.setItem('milestones', JSON.stringify(item.millstones))
		this.router.navigateByUrl('/main/courses/add-class/' + item._id);
	}


	setPage(pageDate: any)
	{
		this.currentPage = pageDate.page;
		this.perPage = pageDate.perPage;
		this.index = this.currentPage;
		this.getList(pageDate.page);
	}

	onSearch()
	{
		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() =>
		{

			this.getList(1);

		}, 800);

	}
	getList(index: any)
	{

		var url = 'view/courses?page=' + index + '&perPage=' + this.perPage + '&sortOrder=DESC';

		if (this.search != '')
		{
			url = url + '&search=' + this.search;
		}
		this.mainService.getList(url).then(response =>
		{
			if (response.code == 200 || response.code === 201)
			{
				console.log(response.data);
				this.Items = response.data.listing;
				this.count = response.data.pagination.count;
				this.currentPage = response.data.pagination.page;
				this.pages = this.paginationService.setPagination(response.data.pagination.count, this.currentPage, this.perPage);
				this.totalPages = this.pages.totalPages;

				this.Items.forEach(element =>
				{
					if (element.otherProps)
					{
						if (element.otherProps.status)
						{
							if (element.otherProps.status.enabled)
							{
								element['courseStatus'] = true;
							}
							else
							{
								element['courseStatus'] = false;
							}
						}
						else
						{
							element['courseStatus'] = false;
						}
					}
				});
			}
			else
			{
				this.Items = [];

			}
		},
			Error =>
			{

			})
	}
	toggleView(item)
	{
		// console.log(item.otherProps.status.enabled);
		var data = {
			enabled: item.courseStatus
		}

		var url = 'update/course/' + item._id + '/status';
		this.mainService.patch(url, data).then(response =>
		{
			if (response.code == 200 || response.code == 201)
			{

			}
			else
			{
				item.courseStatus = !item.courseStatus
			}
		},
			Error =>
			{
				let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
				let cm = dialogRef.componentInstance;
				cm.heading = 'Error';
				cm.message = 'Internal Server Error';
				cm.cancelButtonText = 'Close';
				cm.type = 'error';
			});

	}

	getStatus(item): boolean
	{
		// item.otherProps.status.enabled
		if (item.otherProps)
		{
			if (item.otherProps.status)
			{
				if (item.otherProps.status.enabled)
				{
					return true;
				}
				else
				{
					return false
				}
			}
		}
	}
}
